<ion-header style="background: var(--ion-color-primary);">
  <ion-toolbar color="primary">
      <ion-title *ngIf="platform.is('ios')" style="background: var(--ion-color-primary); color: white; font-size: 20px; margin-left: -4px;"> 
        {{title}}
      </ion-title>

      <ion-buttons slot="start">
        <ion-back-button defaultHref="home"></ion-back-button>
      </ion-buttons>
      <ion-buttons slot="end" class="toolbar-btn">
        <ion-button (click)="toUserProfile()">
          <ion-icon slot="icon-only" name="person-circle"></ion-icon>
        </ion-button>
      </ion-buttons>
    <ion-title class="ion-margin" *ngIf="!platform.is('ios')" style="max-width: 120px; margin: auto;"> {{ title }} </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true" slot="fixed" scrollY="false" style="padding-top: 0px; background-color: #def7df;">

  <ion-spinner class="spinner" name="bubbles" *ngIf="isFetching && userLoggedIn"></ion-spinner>

  <ion-content class="cart-container-new">

    <!-- CARRELLO PER UTENTE NON LOGGATO -->
    <ion-grid *ngIf="hasCartItems && !userLoggedIn" class="items-new">
      <ion-row style="width: 100%; max-width: 600px; margin: auto;">
        <ion-col size="12" *ngIf="nElementi>0">
          <ion-badge color="danger" style="margin-top: 12px; margin-bottom: 12px; margin-right: 18px; float: right; min-width: 90px;" *ngIf="nElementi!=1">{{nElementi}} elementi</ion-badge>
          <ion-badge color="danger" style="margin-top: 12px; margin-bottom: 12px; margin-right: 18px; float: right; min-width: 90px;" *ngIf="nElementi==1">{{nElementi}} elemento</ion-badge>
        </ion-col>
      </ion-row>
      <ion-row class="cart-item-new" *ngFor="let c of cart; index as index_i">
        <ion-card class="item">
          <ion-card-title color="primary" (click)="toProductDetail(c['id'])"><strong>{{ c['name'] }}</strong></ion-card-title>
          <ion-card-content class="item">
            <ion-grid>
              <ion-row>
                <ion-col size-xs="4" size-sm="4" size-md="4" size-lg="4" size-xl="4" class="img-col">
                  <img src="{{constantUtils.getFullURL(c['image'])}}" onerror="this.src='assets/placeholders/product.jpg'" *ngIf="c['image']" class="item-img-resize-new" (click)="toProductDetail(c['id'])">
                  <img src="assets/placeholders/product.jpg" *ngIf="!c['image']" class="item-img-resize-new" (click)="toProductDetail(c['id'])">
                </ion-col>
                <ion-col size="6" class="img-col">
                  <ion-grid>
                    <ion-row>
                      <ion-col size="12">
                        <p style="margin-top: 2px; margin-bottom: 2px;">
                          <ion-text> Venduto da:</ion-text>
                        </p>
                        <p style="margin-top: 2px; margin-bottom: 2px;">
                          <ion-text color="sea" (click)="toVendor(index_i)">{{c['venditore_nome']}}</ion-text>
                        </p>
                      </ion-col>
                    </ion-row>
                    <ion-row *ngIf="!c['options'] || !c['options'][0]">
                      <ion-col size="12">
                        <ion-text> {{ c['prezzo_vetrina'] }}€ l'uno </ion-text>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </ion-col>
                <ion-col size-xs="2" size-sm="2" size-md="2" size-lg="2" size-xl="2" class="img-col">
                  <ion-button fill="clear" color="danger" (click)="deleteItem(index_i)">
                    <ion-icon slot="icon-only" name="close-circle"></ion-icon>
                  </ion-button>
                </ion-col>
              </ion-row>
              <ion-row *ngIf="c['options'] && c['options'][0]">
                <ion-col size="6" style="text-align: right;">
                  <p style="margin-top: 24px;">
                    <ion-text style="font-size: 14px;">Opzione scelta:</ion-text>
                  </p>
                </ion-col>
                <ion-col size="6" style="text-align: left;">
                  <ion-card style="margin: 6px; cursor:pointer; box-shadow: #26a96c 0 2px 18px; width: fit-content; min-width: 78px;"
                  (click)="toProductDetail(c['id'])">
                    <p style="margin-bottom: 6px; margin-top: 6px; padding-left: 4px;">
                      <ion-text style="font-size: 14px;">{{c['options'][0]['choices'][0]['descrizione']}}</ion-text>
                      <ion-text *ngIf="c['options'][0]['validazione'] && c['options'][0]['descrizione'] && c['options'][0]['validazione']['tipologia_validazione']==VALIDAZIONE_REGEX" 
                        style="font-size: 14px;"> {{c['options'][0]['descrizione']}}</ion-text>
                    </p>
                    <p style="margin-bottom: 6px; margin-top: 6px; padding-left: 4px;">
                      <ion-text style="font-size: 14px;"><strong>{{c['options'][0]['choices'][0]['prezzo_vetrina']}}€</strong></ion-text>
                    </p>
                  </ion-card>
                </ion-col>
              </ion-row>
              <ion-row class="footer">
                      <ion-col size-xs="3" size-sm="3" size-md="3" size-lg="3" size-xl="3" class="footer">
                        <ion-button fill="clear" color="primary" (click)="decrementItemPv(index_i)" class="btn-left">
                          <ion-icon name="remove-circle-outline" class="btn"></ion-icon>
                        </ion-button>
                      </ion-col>
                      <ion-col size-xs="2" size-sm="2" size-md="2" size-lg="2" size-xl="1" class="footer">
                        <ion-text class="quanti">{{ c['quantità'] }}</ion-text>
                      </ion-col>
                      <ion-col size-xs="3" size-sm="3" size-md="3" size-lg="3" size-xl="3" class="footer">
                        <ion-button fill="clear" color="primary" (click)="incrementItemPv(index_i)" class="btn-right">
                          <ion-icon name="add-circle-outline" class="btn"></ion-icon>
                        </ion-button>
                      </ion-col>
                      <ion-col size-xs="4" size-sm="3" size-md="4" size-lg="4" size-xl="5" style="text-align: left; margin: auto;">
                        <ion-text class="costo-item" style="margin-left: 12px;" *ngIf="c['costo_item']"> <strong>{{ c['costo_item'] }}€</strong></ion-text>
                      </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>
        </ion-card>
      </ion-row>
    </ion-grid>

    <!-- CARRELLO PER CLIENTE LOGGATO -->
    <ion-grid *ngIf="hasCartItems && userLoggedIn" class="items-new">
      <ion-row style="width: 100%; max-width: 600px; margin: auto;">
        <ion-col size="12" *ngIf="nElementi>0">
          <ion-badge color="danger" style="margin-top: 12px; margin-bottom: 12px; margin-right: 18px; float: right; min-width: 90px;" *ngIf="nElementi!=1">{{nElementi}} elementi</ion-badge>
          <ion-badge color="danger" style="margin-top: 12px; margin-bottom: 12px; margin-right: 18px; float: right; min-width: 90px;" *ngIf="nElementi==1">{{nElementi}} elemento</ion-badge>
        </ion-col>
      </ion-row>
      <ion-row class="cart-item-new" *ngFor="let c of cart; index as index_i">
        <ion-card class="item" *ngIf="c['prodotto']">
          <ion-card-title color="primary" (click)="toProductDetail(c['prodotto']['id'])"><strong>{{ c['prodotto']['nome'] }}</strong></ion-card-title>
          <ion-card-content class="item">
            <ion-grid>
              <ion-row>
                <ion-col size-xs="4" size-sm="4" size-md="4" size-lg="4" size-xl="4" *ngIf="c['prodotto']" class="img-col">
                  <img src="{{ constantUtils.getFullURL(c['prodotto']['immagini'][0]['immagine']) }}" *ngIf="c['prodotto']['immagini'][0]" onerror="this.src='assets/placeholders/product.jpg'" class="item-img-resize-new" (click)="toProductDetail(c['prodotto']['id'])">
                  <img src="assets/placeholders/product.jpg" *ngIf="!c['prodotto']['immagini'][0]" class="item-img-resize-new" (click)="toProductDetail(c['prodotto']['id'])">
                </ion-col>
                <ion-col size="6" class="img-col">
                  <ion-grid>
                    <ion-row>
                      <ion-col size="12">
                        <p style="margin-top: 2px; margin-bottom: 2px;">
                          <ion-text> Venduto da:</ion-text>
                        </p>
                        <p style="margin-top: 2px; margin-bottom: 2px;">
                          <ion-text color="sea" (click)="toVendor(index_i)">{{c.prodotto.venditore.titolo}}</ion-text>
                        </p>
                      </ion-col>
                    </ion-row>
                    <ion-row *ngIf="!c['prodotto']['options'] || !c['prodotto']['options'][0]">
                      <ion-col size="12">
                        <ion-text> {{ c['prezzo_vetrina'] }}€ l'uno </ion-text>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </ion-col>
                <ion-col size-xs="2" size-sm="2" size-md="2" size-lg="2" size-xl="2" class="img-col">
                  <ion-button fill="clear" color="danger" (click)="deleteItem(index_i)" class="no-padding">
                    <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
                  </ion-button>
                </ion-col>
              </ion-row>
              <ion-row *ngIf="c['prodotto']['options'] && c['prodotto']['options'][0] && choicesCartDict[c['choice_id']]">
                <ion-col size="6" style="text-align: right;">
                  <p style="margin-top: 24px;">
                    <ion-text style="font-size: 14px;">Opzione scelta:</ion-text>
                  </p>
                </ion-col>
                <ion-col size="6" style="text-align: left;">
                  <ion-card style="margin: 6px; cursor:pointer; box-shadow: #26a96c 0 2px 18px; width: fit-content; min-width: 78px;"
                  (click)="toProductDetail(c['id'])">
                    <p style="margin-bottom: 6px; margin-top: 6px; padding-left: 4px;">
                      <ion-text style="font-size: 14px;">{{choicesCartDict[c['choice_id']]['descrizione']}}</ion-text>
                      <ion-text *ngIf="optionsCartDict[c['choice_id']]['validazione'] && optionsCartDict[c['choice_id']]['descrizione'] 
                        && optionsCartDict[c['choice_id']]['validazione']['tipologia_validazione']==VALIDAZIONE_REGEX" 
                        style="font-size: 14px;"> {{optionsCartDict[c['choice_id']]['descrizione']}}</ion-text>
                    </p>
                    <p style="margin-bottom: 6px; margin-top: 6px; padding-left: 4px;">
                      <ion-text style="font-size: 14px;"><strong>{{choicesCartDict[c['choice_id']]['prezzo_vetrina']}}€</strong></ion-text>
                    </p>
                  </ion-card>
                </ion-col>
              </ion-row>
              <ion-row class="footer">
                      <ion-col size-xs="3" size-sm="3" size-md="3" size-lg="3" size-xl="3" class="footer">
                        <ion-button fill="clear" color="primary" (click)="decrementItemPv(index_i)" class="btn-left">
                          <ion-icon name="remove-circle-outline" class="btn"></ion-icon>
                        </ion-button>
                      </ion-col>
                      <ion-col size-xs="2" size-sm="2" size-md="2" size-lg="2" size-xl="1" class="footer">
                        <ion-text class="quanti">{{ c['quantità'] }}</ion-text>
                      </ion-col>
                      <ion-col size-xs="3" size-sm="3" size-md="3" size-lg="3" size-xl="3" class="footer">
                        <ion-button fill="clear" color="primary" (click)="incrementItemPv(index_i)" class="btn-right">
                          <ion-icon name="add-circle-outline" class="btn"></ion-icon>
                        </ion-button>
                      </ion-col>
                      <ion-col size-xs="4" size-sm="3" size-md="4" size-lg="4" size-xl="5" style="text-align: left; margin: auto;">
                        <ion-text class="costo-item" style="margin-left: 12px;" *ngIf="c['costo_item']"> <strong>{{ c['costo_item'] }}€</strong></ion-text>
                      </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>
        </ion-card>
      </ion-row>
    </ion-grid>
  </ion-content>

  <div *ngIf="userLoggedIn && !isFetching && error" class="error-div">
    <img src="assets/error/cavolo.jpg" class="error-resize">
    <p class="product-title-data"> Cavolo, qualcosa è andato storto! </p>
    <p class="product-title-data"> Verifica la tua connessione e riprova </p>
    <ion-fab horizontal="center" slot="fixed" class="retry">
      <ion-fab-button color="primary" (click)="onReloadShoppingCart()">
        <ion-icon name="repeat-sharp"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </div>

  <div *ngIf="!isFetching && !error && !hasCartItems" class="error-div">
    <img src="assets/images/no-vendor-products.svg" class="error-resize">
    <p class="product-title-data"> Il tuo carrello è vuoto.. </p>
    <p class="product-title-data"> Inizia a fare acquisti su Emplace! </p>
  </div>
</ion-content>

<ion-footer *ngIf="(userLoggedIn && !isFetching && !error) || !userLoggedIn">
  <ion-grid style="padding: 0px;">
    <ion-row class="tot" style="padding: 0px;">
      <ion-col size-xl="4" size-lg="3" size-md="3" size-sm="3" size-xs="1"></ion-col>
      <ion-col size-xl="3" size-lg="3" size-md="3" size-sm="3" size-xs="6" class="tot">
        <ion-item *ngIf="hasShippingCosts" style="font-size: 12px; color: #777777; --padding-start: 2px; height: 16px; --min-height: 16px;" lines="none">
          <ion-text>Subtotale: <strong>{{roundTo(subtotal,1)}}€</strong></ion-text>
        </ion-item>
        <ion-item *ngIf="hasShippingCosts" style="font-size: 12px; color: #777777; --padding-start: 2px; height: 16px; --min-height: 16px;" lines="none">
          <ion-text><strong>+ {{shippingCostTotal}}€</strong> di spedizione</ion-text>
        </ion-item>

        <p style="margin-bottom: 2px; margin-top: 10px; color: #777777;">
          <ion-label class="totale-label">Totale: <strong>{{ total }}€ </strong></ion-label>
        </p>
      </ion-col>
      <ion-col size-xl="1" size-lg="3" size-md="3" size-sm="4" size-xs="5" class="tot" style="text-align: center;">
        <ion-button class="checkout-btn" color="primary" (click)="toCheckoutPage()" [disabled]="total<=0">Checkout</ion-button>
      </ion-col>
      <ion-col size-xl="3" size-lg="3" size-md="3" size-sm="2"></ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>