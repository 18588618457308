import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { IonInput, NavParams, PopoverController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject } from 'rxjs';
import { BarcodeFormat } from '@zxing/library';
import { BookService } from 'src/app/shared/services/bookService.service';

@Component({
  selector: 'app-book-popover',
  templateUrl: './book-popover.component.html',
  styleUrls: ['./book-popover.component.scss']
})
export class BookPopoverComponent {

  ISBN_MAX: number = 13;
  isFetching: boolean = false;
  missingData: boolean = false;
  requestError: boolean = false;
  notValidISBN: boolean = false;
  isbnCode: string = '';
  private tipologiaVenditore: string;

  comeNuovo: boolean = false;
  ottimo: boolean = false;
  buono: boolean = false;
  usurato: boolean = false;

  @ViewChild('isbn') inputTesto: IonInput;
  private bookDescription: string = '';
  private bookTitle: string = '';
  private BOOK_PROD_TYPE: string = 'BOOK';
  private MAX_DESCRIPTION_LENGTH: number = 2500;

  private STATO_OTTIMO: string = 'Ottimo';
  private STATO_BUONO: string = 'Buono';
  private STATO_COMENUOVO: string = 'Come Nuovo';
  private STATO_USURATO: string = 'Usurato';

  scannerTriggered: boolean = false;
  // Barcode reader variables
  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo = null;

  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];

  hasDevices: boolean;
  hasPermission: boolean;

  qrResultString: string;

  torchEnabled = false;
  torchAvailable$ = new BehaviorSubject<boolean>(false);
  tryHarder = false;

  constructor(private popoverProp: PopoverController, private logger: NGXLogger, private bookService: BookService,
    private router: Router, private activatedRoute: ActivatedRoute, private navParams: NavParams
  ) {
    this.tipologiaVenditore = navParams.get('tipologia');
  }

  ngOnInit(){
    if(!this.tipologiaVenditore){
      this.logger.log('Nessuna tipologia venditore...');
      this.router.navigate(['/myprofile/vendor']);
    }
  }

  onClose(){
    this.popoverProp.dismiss();
  }

  onRetry(){
    this.missingData = false;
    this.requestError = false;
  }

  onChooseStatus(statusCode: number){
    if(statusCode==0){
      this.comeNuovo = true;
      this.ottimo = false;
      this.buono = false;
      this.usurato = false;
    }else if(statusCode==1){
      this.comeNuovo = false;
      this.ottimo = true;
      this.buono = false;
      this.usurato = false;
    }else if(statusCode==2){
      this.comeNuovo = false;
      this.ottimo = false;
      this.buono = true;
      this.usurato = false;
    }else if(statusCode==3){
      this.comeNuovo = false;
      this.ottimo = false;
      this.buono = false;
      this.usurato = true;
    }
  }

  onSendISBN(){
    if(this.inputTesto && this.inputTesto.value.toString()!=null && this.inputTesto.value.toString()!='' && 
      this.inputTesto.value.toString().length>=7){
      this.isFetching = true;
      
      this.bookService.fetchBookByISBN(this.inputTesto.value.toString()).subscribe(
        result => {
          this.logger.log(result);
          this.isFetching = false;
          this.requestError = false;
          this.missingData = false;
          this.notValidISBN = false;

          if(!result['items'][0] || !result['items'][0]['volumeInfo'] || !result['items'][0]['volumeInfo']['title'] || 
            !result['items'][0]['volumeInfo']['authors']
          ){
            this.missingData = true;
          }else{
            // Le info necessarie ci sono, puoi caricare il prodotto 
            this.bookTitle = result['items'][0]['volumeInfo']['title'];
            this.bookDescriptionProducer(result);
            this.endBookDescription();

            var smallIMG: string = null;
            if(result['items'][0]['volumeInfo']['imageLinks'] && result['items'][0]['volumeInfo']['imageLinks']['smallThumbnail']){
              smallIMG = result['items'][0]['volumeInfo']['imageLinks']['smallThumbnail'];
            }

            let navigationExtras: NavigationExtras = {
              state: {
                tipologia: this.tipologiaVenditore,
                productType: this.BOOK_PROD_TYPE,
                bookDescription: this.bookDescription,
                bookTitle: this.bookTitle,
                bookSmallIMG: smallIMG
              }
            };
            this.router.navigate(['/myprofile/vendor/products/add'], navigationExtras);
            this.onClose();
          }
        },
        error => {
          this.logger.log(error);
          this.isFetching = false;
          this.requestError = true;
        }
      )
    }else{
      this.logger.log('onSendISBN() chiamato con un ISBN non valido...');
      this.notValidISBN = true;
    }
  }

  bookDescriptionProducer(raw){
    this.bookDescription = 'Titolo: '+raw['items'][0]['volumeInfo']['title']+'.\n';
    if(raw['items'][0]['volumeInfo']['subtitle']){
      this.bookDescription = this.bookDescription + 'Sottotitolo: '+raw['items'][0]['volumeInfo']['subtitle']+'.\n\n';
    }

    if(raw['items'][0]['volumeInfo']['authors']){
      this.bookDescription = this.bookDescription + 'Autori: ';
    }
    var index = 0;
    for(let author of raw['items'][0]['volumeInfo']['authors']){
      if(index>0){
        this.bookDescription = this.bookDescription + ', ';
      }
      this.bookDescription = this.bookDescription + author;
      index = index + 1;
      if(index==raw['items'][0]['volumeInfo']['authors'].length){
        this.bookDescription = this.bookDescription + '.\n';
      }
    }
    if(raw['items'][0]['volumeInfo']['publisher'] && raw['items'][0]['volumeInfo']['publishedDate']){
      this.bookDescription = this.bookDescription + 'Pubblicato da: '+raw['items'][0]['volumeInfo']['publisher']+
      ', nell\'anno '+raw['items'][0]['volumeInfo']['publishedDate']+'.\n\n\n';
    }

    if(raw['items'][0]['volumeInfo']['description']){
      var description_temp: string = raw['items'][0]['volumeInfo']['description'];
      if(description_temp.length>this.MAX_DESCRIPTION_LENGTH){
        description_temp = description_temp.substring(0,this.MAX_DESCRIPTION_LENGTH);
        description_temp = description_temp + '...\"';
      }
      this.bookDescription = this.bookDescription + 'Descrizione del libro: '+description_temp+'.\n\n\n';
    }

    this.logger.log('bookDescriptionProducer() ends with description = '+this.bookDescription);
  }

  endBookDescription(){
    if(this.comeNuovo){
      this.bookDescription = this.bookDescription + '\nUsato in stato: ' + this.STATO_COMENUOVO + '\n';
    }else if(this.ottimo){
      this.bookDescription = this.bookDescription + '\nUsato in stato: ' + this.STATO_OTTIMO + '\n';
    }else if(this.buono){
      this.bookDescription = this.bookDescription + '\nUsato in stato: ' + this.STATO_BUONO + '\n';
    }else if(this.usurato){
      this.bookDescription = this.bookDescription + '\nUsato in stato: ' + this.STATO_USURATO + '\n';
    }
    this.logger.log('endBookDescription() ends with description = '+this.bookDescription);
  }

  onPhotoISBN(){
    this.scannerTriggered = true;
  }

  onCloseCamera(){
    this.scannerTriggered = false;
  }

  onChangeTextISBN(){
    this.logger.log('BookPopover: onChangeTextISBN new text = '+this.inputTesto.value.toString());
    this.isbnCode = this.inputTesto.value.toString();
  }

  // Managing barcode reader functions... 
  clearResult(): void {
    this.qrResultString = null;
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
    this.logger.log('onCamerasFound: hasDevices = '+this.hasDevices);
    this.logger.log('onCamerasFound: devices found = '+JSON.stringify(this.availableDevices));
  }

  onCodeResult(resultString: string) {
    this.qrResultString = resultString;
    this.logger.log('onCodeResult: resultString = '+this.qrResultString);
    this.isbnCode = this.qrResultString;
    this.logger.log('onCodeResult: isbn = '+this.isbnCode);
    this.scannerTriggered = false;
  }

  onDeviceSelectChange(selected: string) {
    const device = this.availableDevices.find(x => x.deviceId === selected);
    this.currentDevice = device || null;
    this.logger.log('onDeviceSelectChange: current device = '+this.currentDevice)
  }

  openFormatsDialog() {
    const data = {
      formatsEnabled: this.formatsEnabled,
    };

    // Without the following code, all formats enabled? 
    /*this._dialog
      .open(FormatsDialogComponent, { data })
      .afterClosed()
      .subscribe(x => { if (x) { this.formatsEnabled = x; } });*/
  }

  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }

  openInfoDialog() {
    const data = {
      hasDevices: this.hasDevices,
      hasPermission: this.hasPermission,
    };

    // ? 
    //this._dialog.open(AppInfoDialogComponent, { data });
  }

  onTorchCompatible(isCompatible: boolean): void {
    this.torchAvailable$.next(isCompatible || false);
  }

  toggleTorch(): void {
    this.torchEnabled = !this.torchEnabled;
  }

  toggleTryHarder(): void {
    this.tryHarder = !this.tryHarder;
  }
}
