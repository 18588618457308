<ion-header class="classic" color="primary">
  <ion-toolbar color="primary">
    <ion-grid style="padding: 0px;">
      <ion-row style="padding-bottom: 0px; padding-top: 0px;">
        <!-- Bottone laterale per invocare il Menu -->
        <ion-col size-xs="4" size-sm="4" size-md="2" size-lg="2" size-xl="2">
          <ion-buttons *ngIf="!platform.is('ios')">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
          <ion-buttons *ngIf="platform.is('ios')" style="margin-top: 12px;">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
        </ion-col>
        <!-- Titolo per Win e Android -->
        <ion-col size-xs="4" size-sm="4" size-md="3" size-lg="2" size-xl="1" *ngIf="!platform.is('ios')">
          <div style="max-width: 750px; margin: auto; text-align: center;">
            <ion-title style="margin: auto; margin-top: 14px; margin-bottom: 8px; padding-right: 0px; padding-left: 0px;"> 
              <img src='assets/icon/emplace_bianco.png' style="height: 22px; width: 100px; margin-right: 2px;">
            </ion-title>
          </div>
        </ion-col>
        <!-- Titolo per iOs -->
        <!-- E' uguale, per ora.. Lasciato indipendente da quello Win solo per eventuali modifiche future -->
        <ion-col size-xs="4" size-sm="4" size-md="3" size-lg="2" size-xl="1" *ngIf="platform.is('ios')" style="text-align:center;">
          <div style="max-width: 750px; margin: auto; text-align: center;">
            <img src='assets/icon/emplace_bianco.png' style="height: 22px; width: 100px; margin-right: 1px; margin-top: 18px;">
          </div>
        </ion-col>
        <!-- Barra di ricerca su schermi GRANDI (nel mezzo, nella stessa riga) --> <!-- iOs -->
        <ion-col size-xs="12" size-sm="12" size-md="5" size-lg="6" size-xl="7" *ngIf="platform.is('ios') && !smallScreen">
          <ion-searchbar showCancelButton="focus" placeholder="Cerca il prodotto dei tuoi eco-sogni" *ngIf="!isFetching && !errorMsg"
              (ionInput)="onInput($event)" style="padding-top: 13px;"
              (ionCancel)="onCancel($event)"
              (keypress)="onSearch($event)">
          </ion-searchbar>
          <ion-list *ngIf="hasSuggestion" class="searchbar-list" style="padding-left: 32px;">
            <ion-item *ngFor="let s of suggestions" lines="none" (click)="pickSuggestion(s.substring(1,s.length-1))" 
              class="sugg-item"> {{ s.substring(1,s.length-1) }} </ion-item>
          </ion-list>
        </ion-col>
        <!-- Barra di ricerca su schermi GRANDI (nel mezzo, nella stessa riga) --> <!-- WIN e ANDROID -->
        <ion-col size-xs="12" size-sm="12" size-md="5" size-lg="6" size-xl="7" *ngIf="!platform.is('ios') && !smallScreen">
          <ion-searchbar showCancelButton="focus" placeholder="Cerca il prodotto dei tuoi eco-sogni" *ngIf="!isFetching && !errorMsg"
              (ionInput)="onInput($event)"
              (ionCancel)="onCancel($event)"
              (keypress)="onSearch($event)">
          </ion-searchbar>
          <ion-list *ngIf="hasSuggestion" class="searchbar-list">
            <ion-item *ngFor="let s of suggestions" lines="none" (click)="pickSuggestion(s.substring(1,s.length-1))" 
              class="sugg-item"> {{ s.substring(1,s.length-1) }} </ion-item>
          </ion-list>
        </ion-col>
        
        <!-- Bottoni laterali -->
        <ion-col size-xs="4" size-sm="4" size-md="2" size-lg="2" size-xl="2" style="text-align: right; padding-right: 9px;">
          <ion-button (click)="toUserProfile()" fill="clear" style="--padding-start: 0px; --padding-end: 4px; --padding-top: 2px;">
            <ion-badge class="notifications-badge" *ngIf="authService.isLoggedIn() && !userService.userIsClient() && 
                        vendorOrdersService.ordersCounter>0 && !platform.is('ios')">{{ vendorOrdersService.ordersCounter }}</ion-badge>
            <ion-badge class="notifications-badge-ios" *ngIf="authService.isLoggedIn() && !userService.userIsClient() && 
                        vendorOrdersService.ordersCounter>0 && platform.is('ios')">{{ vendorOrdersService.ordersCounter }}</ion-badge>
            <ion-icon slot="icon-only" name="person-circle"></ion-icon>
          </ion-button>
            
          <ion-button *ngIf="(authService.isLoggedIn() && hasRemoteCart) || !authService.isLoggedIn()" (click)="toShoppingCart()" fill="clear" style="--padding-start: 0px; --padding-end: 4px; --padding-top: 2px;">
            <ion-badge class="notifications-badge" *ngIf="shoppingCartManagerService.cartBadge>ZERO_CONSTANT && !platform.is('ios')">{{ shoppingCartManagerService.cartBadge }}</ion-badge>
            <ion-badge class="notifications-badge-ios" *ngIf="shoppingCartManagerService.cartBadge>ZERO_CONSTANT && platform.is('ios')">{{ shoppingCartManagerService.cartBadge }}</ion-badge>
            <ion-icon slot="icon-only" name="cart"></ion-icon>
          </ion-button>
        </ion-col>

        <!-- Su schermo piccolo la barra di ricerca è totale, sulla SECONDA riga -->
        <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12" *ngIf="smallScreen">
          <ion-searchbar showCancelButton="focus" placeholder="Cerca il prodotto dei tuoi eco-sogni" *ngIf="!isFetching && !errorMsg"
              (ionInput)="onInput($event)"
              (ionCancel)="onCancel($event)"
              (keypress)="onSearch($event)">
          </ion-searchbar>
          <ion-list *ngIf="hasSuggestion" class="searchbar-list" style="padding-left: 32px;">
            <ion-item *ngFor="let s of suggestions" lines="none" (click)="pickSuggestion(s.substring(1,s.length-1))" 
              class="sugg-item"> {{ s.substring(1,s.length-1) }} </ion-item>
          </ion-list>
        </ion-col>
      </ion-row>

    </ion-grid>
    
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true" style="padding-top: 0px;">

  <!-- Vecchia barra di ricerca per iOs (sotto la toolbar) -->
  <!--<ion-searchbar showCancelButton="focus" class="ion-margin" placeholder="Cerca il prodotto dei tuoi eco-sogni" *ngIf="platform.is('ios') && !isFetching && !errorMsg"
      (ionInput)="onInput($event)" style="padding-bottom: 0px; max-width: 750px;"
      (ionCancel)="onCancel($event)"
      (keypress)="onSearch($event)"
      inputmode="search">
  </ion-searchbar>-->

  <div id="container" style="margin: 0px; padding: 0px;">
    <ion-grid class="slides" style="padding: 0px;">
      <ion-row style="padding: 0px;">
        <ion-col size=12 style="padding: 0px;">
          <ion-fab horizontal="end" *ngIf="!isFetching && !errorMsg && (hasUserProfileInfo || isNotLoggedIn) && !userService.userIsVendor()" style="margin-top: 8px;">
            <ion-fab-button title="Animal" (click)="onAnimalPressed()" class="animal">
              <img src="{{ animalPicture }}" onerror="this.src='assets/icon/animal/kiwi.JPG'" class="user-animal">
            </ion-fab-button>
          </ion-fab>
          
          <swiper-container pagination="true" loop="true" autoplay="true" slidesPerView="1" initialSlide="0" speed="500"
          disableOnInteraction="false" class="home-slides" *ngIf="!isFetching && !errorMsg">
            <!-- PRIMA SLIDE -->
            <swiper-slide>
                <img class="home-slide-img" src="assets/images/waterfalls.jpg"/>
                <div style="width: 100%; height: 80%; color: #fff; margin-top: 120px; position: absolute;" class="card-title">
                  <p style="margin:0px; padding-left: 8px; padding-right: 8px; margin-bottom: 16px;" class="main-title"><strong>Bentornatə su Emplace!</strong></p>
                  <p style="margin:0px; padding-left: 8px; padding-right: 8px;">Il marketplace dove decidi tu come salvare il mondo.</p>
                  <p style="margin:0px; padding-left: 8px; padding-right: 8px;">Prodotti sostenibili per l'ambiente e per le persone</p>
                </div>
                <!-- Menu Grid -->
                <div style="position: absolute; width: 100%; margin-top: 330px;" class="card-title">
                  <ion-grid style="background: rgba(255, 255, 255, 0.3);">
                    <ion-row style="max-width: 1200px; padding-left: 6px; padding-right: 6px; margin: auto;">
                      <ion-col size-xs="4" size-sm="4" size-md="4" size-lg="4" size-xl="4" style="text-align: left;">
                        <ion-row style="padding: 0px;"><ion-col size="12" style="padding: 0px;">
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 0px; cursor:pointer;" *ngIf="xsScreen" (click)="toSaveThePlanet()">Salviamo</p>
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 2px; cursor:pointer;" *ngIf="xsScreen" (click)="toSaveThePlanet()">il pianeta</p>
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 6px; cursor:pointer;" *ngIf="!xsScreen" (click)="toSaveThePlanet()">Salviamo il pianeta</p>
                          <hr style="height: 1px; margin: 0px; background: #ffffff;">
                        </ion-col></ion-row>
                        <ion-row style="padding: 0px;"><ion-col size="12"></ion-col></ion-row>
                        <ion-row style="padding: 0px;"><ion-col size="12" style="padding: 0px;">
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 10px; cursor:pointer;" *ngIf="xsScreen" (click)="toMission()">Mission</p>
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 6px; cursor:pointer;" *ngIf="!xsScreen" (click)="toMission()">La nostra Mission</p>
                          <hr style="height: 1px; margin: 0px; background: #ffffff;">
                        </ion-col></ion-row>
                        <ion-row style="padding: 0px;"><ion-col size="12"></ion-col></ion-row>
                        <!--<ion-row style="padding: 0px;"><ion-col size="12" style="padding: 0px;">
                          <p style="margin:0px;" (click)="toUs()">Chi siamo</p></ion-col></ion-row>-->
                        <ion-row style="padding: 0px;"><ion-col size="12" style="padding: 0px;">
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 0px; cursor:pointer;" *ngIf="xsScreen" (click)="toHowItWorks()">Come</p>
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 2px; cursor:pointer;" *ngIf="xsScreen" (click)="toHowItWorks()">funziona</p>
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 6px; cursor:pointer;" *ngIf="!xsScreen" (click)="toHowItWorks()">Come funziona</p>
                          <hr style="height: 1px; margin: 0px; background: #ffffff;">
                        </ion-col></ion-row>
                        <ion-row style="padding: 0px;"><ion-col size="12"></ion-col></ion-row>
                      </ion-col>
                      <ion-col size-xs="4" size-sm="4" size-md="4" size-lg="4" size-xl="4">
                        <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 18px;" (click)="toScopriDiPiu()">Vendi i tuoi prodotti sostenibili</p>
                        <!--<p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 2px;" (click)="toScopriDiPiu()">prodotti</p>
                        <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 2px;" (click)="toScopriDiPiu()">sostenibili</p>-->
                        <ion-button color="primary" style="height:30px;" class="btn-title" 
                          (click)="toScopriDiPiu()">Inizia ora</ion-button>
                      </ion-col>
                      <ion-col size-xs="4" size-sm="4" size-md="4" size-lg="4" size-xl="4" style="text-align: right;">
                        <ion-row style="padding: 0px;"><ion-col size="12" style="padding: 0px;">
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 0px; cursor:pointer;" *ngIf="xsScreen" (click)="toPaginaProdotti('TOP_SOSTENIBILI')">Top</p>
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 2px; cursor:pointer;" *ngIf="xsScreen" (click)="toPaginaProdotti('TOP_SOSTENIBILI')">sostenibili</p>
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 6px; cursor:pointer;" *ngIf="!xsScreen" (click)="toPaginaProdotti('TOP_SOSTENIBILI')">Top sostenibili</p>
                          <hr style="height: 1px; margin: 0px; background: #ffffff; float: right;">
                        </ion-col></ion-row>
                        <ion-row style="padding: 0px;"><ion-col size="12"></ion-col></ion-row>
                        <ion-row style="padding: 0px;"><ion-col size="12" style="padding: 0px;">
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 10px; cursor:pointer;" *ngIf="xsScreen" (click)="toPaginaProdotti('NOVITA')">Novità</p>
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 6px; cursor:pointer;" *ngIf="!xsScreen" (click)="toPaginaProdotti('NOVITA')">Novità</p>
                          <hr style="height: 1px; margin: 0px; background: #ffffff; float: right;">
                        </ion-col></ion-row>
                        <ion-row style="padding: 0px;"><ion-col size="12"></ion-col></ion-row>
                        <ion-row style="padding: 0px;"><ion-col size="12" style="padding: 0px;">
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 0px; cursor:pointer;" *ngIf="xsScreen" (click)="toPaginaProdotti('RECENSITI')">I più</p>
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 2px; cursor:pointer;" *ngIf="xsScreen" (click)="toPaginaProdotti('RECENSITI')">venduti</p>
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 6px; cursor:pointer;" *ngIf="!xsScreen" (click)="toPaginaProdotti('RECENSITI')">I più venduti</p>
                          <hr style="height: 1px; margin: 0px; background: #ffffff; float: right;">
                        </ion-col></ion-row>
                        <ion-row style="padding: 0px;"><ion-col size="12"></ion-col></ion-row>
                        <!--<ion-row style="padding: 0px;" (click)="toCategoryPage()"><ion-col size="12" (click)="toCategoryPage()"></ion-col></ion-row>
                        <ion-row style="padding: 0px;"><ion-col size="12" style="padding: 0px;">
                          <p style="margin:0px;" (click)="toCategoryPage()">Tutte le categorie</p></ion-col></ion-row>-->
                        <ion-row style="padding: 0px;"><ion-col size="12"></ion-col></ion-row>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </div>
                <!-- Fine Menu Grid -->
            </swiper-slide>
            <!-- SECONDA SLIDE (FORESTA) -->
            <swiper-slide> 
                <img class="home-slide-img" src="assets/images/forest_dark.jpg" (click)="toSaveThePlanet()"/>
                <div style="width: 100%; height: 70%; color: #fff; margin-top: 120px; position: absolute;" class="card-title-rev">
                  <p style="margin:0px; padding-left: 8px; padding-right: 8px; text-shadow: 1px 1px 1px black, 1px 1px 1px black;">
                    <strong>Acquistandoli proteggiamo le nostre foreste</strong>
                  </p>
                  <ion-button color="sea" style="height:30px; margin-top: 8px;" class="btn-title" (click)="toSaveThePlanet()">Scopri come</ion-button>
                </div>
                <!-- Menu Grid -->
                <div style="position: absolute; width: 100%; margin-top: 330px;" class="card-title">
                  <ion-grid style="background: rgba(255, 255, 255, 0.3);">
                    <ion-row style="max-width: 1200px; padding-left: 6px; padding-right: 6px; margin: auto;">
                      <ion-col size-xs="4" size-sm="4" size-md="4" size-lg="4" size-xl="4" style="text-align: left;">
                        <ion-row style="padding: 0px;"><ion-col size="12" style="padding: 0px;">
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 0px; cursor:pointer;" *ngIf="xsScreen" (click)="toSaveThePlanet()">Salviamo</p>
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 2px; cursor:pointer;" *ngIf="xsScreen" (click)="toSaveThePlanet()">il pianeta</p>
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 6px; cursor:pointer;" *ngIf="!xsScreen" (click)="toSaveThePlanet()">Salviamo il pianeta</p>
                          <hr style="height: 1px; margin: 0px; background: #ffffff;">
                        </ion-col></ion-row>
                        <ion-row style="padding: 0px;"><ion-col size="12"></ion-col></ion-row>
                        <ion-row style="padding: 0px;"><ion-col size="12" style="padding: 0px;">
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 10px; cursor:pointer;" *ngIf="xsScreen" (click)="toMission()">Mission</p>
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 6px; cursor:pointer;" *ngIf="!xsScreen" (click)="toMission()">La nostra Mission</p>
                          <hr style="height: 1px; margin: 0px; background: #ffffff;">
                        </ion-col></ion-row>
                        <ion-row style="padding: 0px;"><ion-col size="12"></ion-col></ion-row>
                        <!--<ion-row style="padding: 0px;"><ion-col size="12" style="padding: 0px;">
                          <p style="margin:0px;" (click)="toUs()">Chi siamo</p></ion-col></ion-row>-->
                        <ion-row style="padding: 0px;"><ion-col size="12" style="padding: 0px;">
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 0px; cursor:pointer;" *ngIf="xsScreen" (click)="toHowItWorks()">Come</p>
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 2px; cursor:pointer;" *ngIf="xsScreen" (click)="toHowItWorks()">funziona</p>
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 6px; cursor:pointer;" *ngIf="!xsScreen" (click)="toHowItWorks()">Come funziona</p>
                          <hr style="height: 1px; margin: 0px; background: #ffffff;">
                        </ion-col></ion-row>
                        <ion-row style="padding: 0px;"><ion-col size="12"></ion-col></ion-row>
                      </ion-col>
                      <ion-col size-xs="4" size-sm="4" size-md="4" size-lg="4" size-xl="4">
                        <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 18px;" (click)="toScopriDiPiu()">Vendi i tuoi prodotti sostenibili</p>
                        <!--<p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 2px;" (click)="toScopriDiPiu()">prodotti</p>
                        <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 2px;" (click)="toScopriDiPiu()">sostenibili</p>-->
                        <ion-button color="primary" style="height:30px;" class="btn-title" 
                          (click)="toScopriDiPiu()">Inizia ora</ion-button>
                      </ion-col>
                      <ion-col size-xs="4" size-sm="4" size-md="4" size-lg="4" size-xl="4" style="text-align: right;">
                        <ion-row style="padding: 0px;"><ion-col size="12" style="padding: 0px;">
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 0px; cursor:pointer;" *ngIf="xsScreen" (click)="toPaginaProdotti('TOP_SOSTENIBILI')">Top</p>
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 2px; cursor:pointer;" *ngIf="xsScreen" (click)="toPaginaProdotti('TOP_SOSTENIBILI')">sostenibili</p>
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 6px; cursor:pointer;" *ngIf="!xsScreen" (click)="toPaginaProdotti('TOP_SOSTENIBILI')">Top sostenibili</p>
                          <hr style="height: 1px; margin: 0px; background: #ffffff; float: right;">
                        </ion-col></ion-row>
                        <ion-row style="padding: 0px;"><ion-col size="12"></ion-col></ion-row>
                        <ion-row style="padding: 0px;"><ion-col size="12" style="padding: 0px;">
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 10px; cursor:pointer;" *ngIf="xsScreen" (click)="toPaginaProdotti('NOVITA')">Novità</p>
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 6px; cursor:pointer;" *ngIf="!xsScreen" (click)="toPaginaProdotti('NOVITA')">Novità</p>
                          <hr style="height: 1px; margin: 0px; background: #ffffff; float: right;">
                        </ion-col></ion-row>
                        <ion-row style="padding: 0px;"><ion-col size="12"></ion-col></ion-row>
                        <ion-row style="padding: 0px;"><ion-col size="12" style="padding: 0px;">
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 0px; cursor:pointer;" *ngIf="xsScreen" (click)="toPaginaProdotti('RECENSITI')">I più</p>
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 2px; cursor:pointer;" *ngIf="xsScreen" (click)="toPaginaProdotti('RECENSITI')">venduti</p>
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 6px; cursor:pointer;" *ngIf="!xsScreen" (click)="toPaginaProdotti('RECENSITI')">I più venduti</p>
                          <hr style="height: 1px; margin: 0px; background: #ffffff; float: right;">
                        </ion-col></ion-row>
                        <ion-row style="padding: 0px;"><ion-col size="12"></ion-col></ion-row>
                        <!--<ion-row style="padding: 0px;" (click)="toCategoryPage()"><ion-col size="12" (click)="toCategoryPage()"></ion-col></ion-row>
                        <ion-row style="padding: 0px;"><ion-col size="12" style="padding: 0px;">
                          <p style="margin:0px;" (click)="toCategoryPage()">Tutte le categorie</p></ion-col></ion-row>-->
                        <ion-row style="padding: 0px;"><ion-col size="12"></ion-col></ion-row>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </div>
                <!-- Fine Menu Grid -->
            </swiper-slide>
            <!-- TERZA SLIDE (MARI) -->
            <swiper-slide>
                <img class="home-slide-img" src="assets/images/sea_dark.jpg" (click)="toSaveThePlanet()"/>
                <div style="width: 100%; height: 70%; color: #fff; margin-top: 120px; position: absolute;" class="card-title-rev">
                  <p style="margin:0px; padding-left: 8px; padding-right: 8px; text-shadow: 1px 1px 1px black, 1px 1px 1px black;">
                    <strong>Ripuliamo i nostri oceani e le nostre spiagge</strong>
                  </p>
                  <ion-button color="sea" style="height:30px; margin-top: 8px;" class="btn-title" (click)="toSaveThePlanet()">Scopri come</ion-button>
                </div>
                <!-- Menu Grid -->
                <div style="position: absolute; width: 100%; margin-top: 330px;" class="card-title">
                  <ion-grid style="background: rgba(255, 255, 255, 0.3);">
                    <ion-row style="max-width: 1200px; padding-left: 6px; padding-right: 6px; margin: auto;">
                      <ion-col size-xs="4" size-sm="4" size-md="4" size-lg="4" size-xl="4" style="text-align: left;">
                        <ion-row style="padding: 0px;"><ion-col size="12" style="padding: 0px;">
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 0px; cursor:pointer;" *ngIf="xsScreen" (click)="toSaveThePlanet()">Salviamo</p>
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 2px; cursor:pointer;" *ngIf="xsScreen" (click)="toSaveThePlanet()">il pianeta</p>
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 6px; cursor:pointer;" *ngIf="!xsScreen" (click)="toSaveThePlanet()">Salviamo il pianeta</p>
                          <hr style="height: 1px; margin: 0px; background: #ffffff;">
                        </ion-col></ion-row>
                        <ion-row style="padding: 0px;"><ion-col size="12"></ion-col></ion-row>
                        <ion-row style="padding: 0px;"><ion-col size="12" style="padding: 0px;">
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 10px; cursor:pointer;" *ngIf="xsScreen" (click)="toMission()">Mission</p>
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 6px; cursor:pointer;" *ngIf="!xsScreen" (click)="toMission()">La nostra Mission</p>
                          <hr style="height: 1px; margin: 0px; background: #ffffff;">
                        </ion-col></ion-row>
                        <ion-row style="padding: 0px;"><ion-col size="12"></ion-col></ion-row>
                        <!--<ion-row style="padding: 0px;"><ion-col size="12" style="padding: 0px;">
                          <p style="margin:0px;" (click)="toUs()">Chi siamo</p></ion-col></ion-row>-->
                        <ion-row style="padding: 0px;"><ion-col size="12" style="padding: 0px;">
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 0px; cursor:pointer;" *ngIf="xsScreen" (click)="toHowItWorks()">Come</p>
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 2px; cursor:pointer;" *ngIf="xsScreen" (click)="toHowItWorks()">funziona</p>
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 6px; cursor:pointer;" *ngIf="!xsScreen" (click)="toHowItWorks()">Come funziona</p>
                          <hr style="height: 1px; margin: 0px; background: #ffffff;">
                        </ion-col></ion-row>
                        <ion-row style="padding: 0px;"><ion-col size="12"></ion-col></ion-row>
                      </ion-col>
                      <ion-col size-xs="4" size-sm="4" size-md="4" size-lg="4" size-xl="4">
                        <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 18px;" (click)="toScopriDiPiu()">Vendi i tuoi prodotti sostenibili</p>
                        <!--<p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 2px;" (click)="toScopriDiPiu()">prodotti</p>
                        <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 2px;" (click)="toScopriDiPiu()">sostenibili</p>-->
                        <ion-button color="primary" style="height:30px;" class="btn-title" 
                          (click)="toScopriDiPiu()">Inizia ora</ion-button>
                      </ion-col>
                      <ion-col size-xs="4" size-sm="4" size-md="4" size-lg="4" size-xl="4" style="text-align: right;">
                        <ion-row style="padding: 0px;"><ion-col size="12" style="padding: 0px;">
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 0px; cursor:pointer;" *ngIf="xsScreen" (click)="toPaginaProdotti('TOP_SOSTENIBILI')">Top</p>
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 2px; cursor:pointer;" *ngIf="xsScreen" (click)="toPaginaProdotti('TOP_SOSTENIBILI')">sostenibili</p>
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 6px; cursor:pointer;" *ngIf="!xsScreen" (click)="toPaginaProdotti('TOP_SOSTENIBILI')">Top sostenibili</p>
                          <hr style="height: 1px; margin: 0px; background: #ffffff; float: right;">
                        </ion-col></ion-row>
                        <ion-row style="padding: 0px;"><ion-col size="12"></ion-col></ion-row>
                        <ion-row style="padding: 0px;"><ion-col size="12" style="padding: 0px;">
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 10px; cursor:pointer;" *ngIf="xsScreen" (click)="toPaginaProdotti('NOVITA')">Novità</p>
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 6px; cursor:pointer;" *ngIf="!xsScreen" (click)="toPaginaProdotti('NOVITA')">Novità</p>
                          <hr style="height: 1px; margin: 0px; background: #ffffff; float: right;">
                        </ion-col></ion-row>
                        <ion-row style="padding: 0px;"><ion-col size="12"></ion-col></ion-row>
                        <ion-row style="padding: 0px;"><ion-col size="12" style="padding: 0px;">
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 0px; cursor:pointer;" *ngIf="xsScreen" (click)="toPaginaProdotti('RECENSITI')">I più</p>
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 2px; cursor:pointer;" *ngIf="xsScreen" (click)="toPaginaProdotti('RECENSITI')">venduti</p>
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 6px; cursor:pointer;" *ngIf="!xsScreen" (click)="toPaginaProdotti('RECENSITI')">I più venduti</p>
                          <hr style="height: 1px; margin: 0px; background: #ffffff; float: right;">
                        </ion-col></ion-row>
                        <ion-row style="padding: 0px;"><ion-col size="12"></ion-col></ion-row>
                        <!--<ion-row style="padding: 0px;" (click)="toCategoryPage()"><ion-col size="12" (click)="toCategoryPage()"></ion-col></ion-row>
                        <ion-row style="padding: 0px;"><ion-col size="12" style="padding: 0px;">
                          <p style="margin:0px;" (click)="toCategoryPage()">Tutte le categorie</p></ion-col></ion-row>-->
                        <ion-row style="padding: 0px;"><ion-col size="12"></ion-col></ion-row>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </div>
                <!-- Fine Menu Grid -->
            </swiper-slide>
            <!-- QUARTA SLIDE (SOCIALE) -->
            <swiper-slide>
                <img class="home-slide-img" src="assets/images/social.png" (click)="toSaveThePlanet()"/>
                <div style="width: 100%; height: 70%; color: #fff; margin-top: 120px; position: absolute;" class="card-title-rev">
                  <p style="margin:0px; padding-left: 8px; padding-right: 8px; text-shadow: 1px 1px 1px black, 1px 1px 1px black;">
                    <strong>Supportiamo le nostre comunità</strong>
                  </p>
                  <ion-button color="sea" style="height:30px; margin-top: 8px;" class="btn-title" (click)="toSaveThePlanet()">Scopri come</ion-button>
                </div>
                <!-- Menu Grid -->
                <div style="position: absolute; width: 100%; margin-top: 330px;" class="card-title">
                  <ion-grid style="background: rgba(255, 255, 255, 0.3);">
                    <ion-row style="max-width: 1200px; padding-left: 6px; padding-right: 6px; margin: auto;">
                      <ion-col size-xs="4" size-sm="4" size-md="4" size-lg="4" size-xl="4" style="text-align: left;">
                        <ion-row style="padding: 0px;"><ion-col size="12" style="padding: 0px;">
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 0px; cursor:pointer;" *ngIf="xsScreen" (click)="toSaveThePlanet()">Salviamo</p>
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 2px; cursor:pointer;" *ngIf="xsScreen" (click)="toSaveThePlanet()">il pianeta</p>
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 6px; cursor:pointer;" *ngIf="!xsScreen" (click)="toSaveThePlanet()">Salviamo il pianeta</p>
                          <hr style="height: 1px; margin: 0px; background: #ffffff;">
                        </ion-col></ion-row>
                        <ion-row style="padding: 0px;"><ion-col size="12"></ion-col></ion-row>
                        <ion-row style="padding: 0px;"><ion-col size="12" style="padding: 0px;">
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 10px; cursor:pointer;" *ngIf="xsScreen" (click)="toMission()">Mission</p>
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 6px; cursor:pointer;" *ngIf="!xsScreen" (click)="toMission()">La nostra Mission</p>
                          <hr style="height: 1px; margin: 0px; background: #ffffff;">
                        </ion-col></ion-row>
                        <ion-row style="padding: 0px;"><ion-col size="12"></ion-col></ion-row>
                        <!--<ion-row style="padding: 0px;"><ion-col size="12" style="padding: 0px;">
                          <p style="margin:0px;" (click)="toUs()">Chi siamo</p></ion-col></ion-row>-->
                        <ion-row style="padding: 0px;"><ion-col size="12" style="padding: 0px;">
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 0px; cursor:pointer;" *ngIf="xsScreen" (click)="toHowItWorks()">Come</p>
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 2px; cursor:pointer;" *ngIf="xsScreen" (click)="toHowItWorks()">funziona</p>
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 6px; cursor:pointer;" *ngIf="!xsScreen" (click)="toHowItWorks()">Come funziona</p>
                          <hr style="height: 1px; margin: 0px; background: #ffffff;">
                        </ion-col></ion-row>
                        <ion-row style="padding: 0px;"><ion-col size="12"></ion-col></ion-row>
                      </ion-col>
                      <ion-col size-xs="4" size-sm="4" size-md="4" size-lg="4" size-xl="4">
                        <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 18px;" (click)="toScopriDiPiu()">Vendi i tuoi prodotti sostenibili</p>
                        <!--<p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 2px;" (click)="toScopriDiPiu()">prodotti</p>
                        <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 2px;" (click)="toScopriDiPiu()">sostenibili</p>-->
                        <ion-button color="primary" style="height:30px;" class="btn-title" 
                          (click)="toScopriDiPiu()">Inizia ora</ion-button>
                      </ion-col>
                      <ion-col size-xs="4" size-sm="4" size-md="4" size-lg="4" size-xl="4" style="text-align: right;">
                        <ion-row style="padding: 0px;"><ion-col size="12" style="padding: 0px;">
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 0px; cursor:pointer;" *ngIf="xsScreen" (click)="toPaginaProdotti('TOP_SOSTENIBILI')">Top</p>
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 2px; cursor:pointer;" *ngIf="xsScreen" (click)="toPaginaProdotti('TOP_SOSTENIBILI')">sostenibili</p>
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 6px; cursor:pointer;" *ngIf="!xsScreen" (click)="toPaginaProdotti('TOP_SOSTENIBILI')">Top sostenibili</p>
                          <hr style="height: 1px; margin: 0px; background: #ffffff; float: right;">
                        </ion-col></ion-row>
                        <ion-row style="padding: 0px;"><ion-col size="12"></ion-col></ion-row>
                        <ion-row style="padding: 0px;"><ion-col size="12" style="padding: 0px;">
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 10px; cursor:pointer;" *ngIf="xsScreen" (click)="toPaginaProdotti('NOVITA')">Novità</p>
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 6px; cursor:pointer;" *ngIf="!xsScreen" (click)="toPaginaProdotti('NOVITA')">Novità</p>
                          <hr style="height: 1px; margin: 0px; background: #ffffff; float: right;">
                        </ion-col></ion-row>
                        <ion-row style="padding: 0px;"><ion-col size="12"></ion-col></ion-row>
                        <ion-row style="padding: 0px;"><ion-col size="12" style="padding: 0px;">
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 0px; cursor:pointer;" *ngIf="xsScreen" (click)="toPaginaProdotti('RECENSITI')">I più</p>
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 2px; cursor:pointer;" *ngIf="xsScreen" (click)="toPaginaProdotti('RECENSITI')">venduti</p>
                          <p style="margin:0px; text-shadow: 1px 1px 3px black, 1px 1px 3px black; margin-top: 6px; cursor:pointer;" *ngIf="!xsScreen" (click)="toPaginaProdotti('RECENSITI')">I più venduti</p>
                          <hr style="height: 1px; margin: 0px; background: #ffffff; float: right;">
                        </ion-col></ion-row>
                        <ion-row style="padding: 0px;"><ion-col size="12"></ion-col></ion-row>
                        <!--<ion-row style="padding: 0px;" (click)="toCategoryPage()"><ion-col size="12" (click)="toCategoryPage()"></ion-col></ion-row>
                        <ion-row style="padding: 0px;"><ion-col size="12" style="padding: 0px;">
                          <p style="margin:0px;" (click)="toCategoryPage()">Tutte le categorie</p></ion-col></ion-row>-->
                        <ion-row style="padding: 0px;"><ion-col size="12"></ion-col></ion-row>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </div>
                <!-- Fine Menu Grid -->
            </swiper-slide>
          </swiper-container>
        </ion-col>
      </ion-row>
    </ion-grid>

    <!-- Vecchio banner per attirare i nuovi venditori -->
    <!--<ion-grid class="slides" style="padding: 0px;" *ngIf="sezioni && sezioni[0]">
      <ion-card color="darksea" style="margin-inline: 0px;">
        <ion-row>
          <ion-col size-xs="12" size-sm="9" size-md="8" size-lg="9" size-xl="9" style="margin-top: 14px; text-align: center;">
            <ion-text class="sea-banner" style="color: #ffffff;">Vuoi vendere su Emplace i tuoi prodotti <strong>ecosostenibili?</strong></ion-text>
          </ion-col>
          <ion-col size-xs="12" size-sm="3" size-md="4" size-lg="3" size-xl="2" style="padding-left: 0px; text-align: center;">
            <ion-button color="primary" (click)="toScopriDiPiu()">Scopri di più</ion-button>
          </ion-col>
        </ion-row>
      </ion-card>
    </ion-grid>    -->
    
    <!-- SEZIONI DELLA HOME -->
    <div style="width: 100%; padding-left: 8px; padding-right: 8px;">
      <app-sezione-home *ngFor="let sezione of sezioni" [sezione]="sezione"></app-sezione-home>

      <ion-spinner name="bubbles"  *ngIf="isFetching"></ion-spinner>
      
      <ion-grid class="message" style="padding: 0px;" *ngIf="sezioni && sezioni[0]">
        <ion-card style="margin-inline: 0px; background-image: linear-gradient(to bottom right, #22577A, #38A3A5);">
          <ion-row style="max-width: 600px; margin: auto;">
            <ion-col size-xs="12" size-sm="7" size-md="7" size-lg="7" size-xl="7" style="margin-top: 14px; text-align: center;">
              <ion-text style="color: #ffffff;" class="sea-banner">Non hai trovato il venditore che cercavi? </ion-text>
            </ion-col>
            <ion-col size-xs="12" size-sm="5" size-md="5" size-lg="4" size-xl="4" style="padding-left: 0px; text-align: center;">
              <ion-button color="primary" [href]="email">Suggeriscilo!</ion-button>
            </ion-col>
          </ion-row>
        </ion-card>
      </ion-grid>   
    
    </div>

    <div *ngIf="errorMsg && !isFetching" class="error-div">
      <img src="assets/error/cavolo.jpg" class="error-resize">
      <p class="error-msg"> Cavolo, qualcosa è andato storto! </p>
      <p class="error-msg"> Verifica la tua connessione e riprova </p>
      <ion-fab horizontal="center" slot="fixed" class="retry">
        <ion-fab-button color="primary" (click)="onReloadHomeData()">
          <ion-icon name="repeat-sharp"></ion-icon>
        </ion-fab-button>
      </ion-fab>
    </div>

  </div>

  <div style="position: absolute; width: 100%; bottom: 0;" *ngIf="sezioni && !sezioni[0]">
    <div style="margin:auto; max-width:750px; font-size: 12px; color: #777777;">
      <p style="padding-left: 12px; cursor: pointer;">
        Scopri la nostra <strong><a (click)="toMission()" color="primary">Mission</a></strong>
      </p>
      <p style="padding-left: 12px; cursor: pointer;">
        Scopri <strong><a (click)="toHowItWorks()" color="primary">Come funziona Emplace</a></strong>
      </p>
      <p style="padding-left: 12px;">
        <a href="#" class="iubenda-cs-preferences-link">Aggiorna le tue preferenze riguardo i Cookies</a>
      </p>
      <p style="padding-left: 12px;">
        Consulta la nostra 
        <a href="https://www.iubenda.com/privacy-policy/10266349" class="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy ">Privacy Policy</a>
        <script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>
      </p>
    </div>
  </div>
  <div style="margin-top: 0px; width: 100%; background-image: linear-gradient(to bottom right, #26a96c, #57cc99); margin-bottom: -12px;" *ngIf="sezioni && sezioni[0]">
    <!-- DIV FOOTER -->
    <div style="margin:auto; max-width:600px; font-size: 12px; color: #ffffff;">
      <ion-grid>
        <!-- FOOTER GRID -->
        <ion-row style="margin-bottom: 8px;">
          <ion-col size="12" style="text-align: left;">
            <p style="padding-left: 12px; font-size: 14px;"><strong>Bisogno di aiuto?</strong></p>
            <p style="padding-left: 12px; font-size: 14px; cursor:pointer; margin-bottom: 8px;">
              Contatta la nostra <strong (click)="emailToAssistenza()" style="cursor:pointer;">assistenza</strong> via 
              <a style="color: #ffffff;" [href]="emailAssistenza">email</a> oppure il nostro 
              <strong (click)="toShowWAppNumber()" style="cursor:pointer;">servizio clienti</strong> via 
              <u style="color: #ffffff;" (click)="toShowWAppNumber()">WhatsApp</u>!</p>
            <p color="#ffffff" style="text-align: center; margin-top: 8px; cursor:pointer;" (click)="toShowWAppNumber()">
              <ion-icon name="mail-outline" style="width: 25px; height: 25px; margin-bottom: -8px;"></ion-icon>
              <ion-icon name="logo-whatsapp" style="margin-left:24px; width: 25px; height: 25px; margin-bottom: -8px;"></ion-icon>
              <ion-text color="#ffffff" style="font-size: 12px; padding-left: 8px;" *ngIf="showEmplaceBsnNumero">{{emplace_bsn_numero}}</ion-text>
            </p>
          </ion-col>
        </ion-row>
        <ion-row style="margin-bottom: 24px;">
          <ion-col size="12" style="text-align: left;">
            <p style="padding-left: 12px; font-size: 14px; cursor: pointer;" (click)="toInstagram()">
              Oppure <strong>seguici su Instagram!</strong>
              <ion-icon name="logo-instagram" style="width: 25px; height: 25px; margin-bottom: -7px; margin-left: 12px; cursor: pointer;"></ion-icon>
            </p>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="6">
            <p style="padding-left: 12px; font-size: 14px;">
              <strong>Su di noi</strong>
            </p>
          </ion-col>
          <ion-col size="6">
            <p style="padding-left: 24px; font-size: 14px;">
              <strong>Tutela</strong>
            </p>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="6">
            <p style="padding-left: 12px; margin-bottom: 0px; cursor: pointer; margin-top: 8px;">
              Scopri la nostra <strong><a (click)="toSaveThePlanet()" style="color: #ffffff;">Mission</a></strong>
            </p>
          </ion-col>
          <ion-col size="6">
            <p style="padding-left: 24px; margin-bottom: 0px; margin-top: 8px;">
              Consulta la nostra 
              <a href="https://www.iubenda.com/privacy-policy/10266349/cookie-policy" style="color: #ffffff;" class="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy ">Cookie Policy</a>
            </p>
          </ion-col>
          <!--<ion-col size="6">
            <p style="padding-left: 12px; margin-bottom: 0px; margin-top: 8px;">
              <a href="#" class="iubenda-cs-preferences-link" style="color: #ffffff;">Aggiorna le tue preferenze riguardo i Cookies</a>
            </p>
          </ion-col>-->
        </ion-row>
        <ion-row>
          <ion-col size="6">
            <p style="padding-left: 12px; margin-bottom: 0px; margin-top: 8px;">
              Scopri <strong><a (click)="toHowItWorks()" style="color: #ffffff;">Come funziona Emplace</a></strong>
            </p>
          </ion-col>
          <ion-col size="6">
            <p style="padding-left: 24px; color: #ffffff; margin-bottom: 0px; margin-top: 8px;">
              Consulta la nostra 
              <a href="https://www.iubenda.com/privacy-policy/10266349" style="color: #ffffff;" class="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy ">Privacy Policy</a>
              <script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>
            </p>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="6">
            <p style="padding-left: 12px; cursor: pointer;">
              <strong><a (click)="toUs()" style="color: #ffffff;">Chi siamo</a></strong>
            </p>
          </ion-col>
          <ion-col size="6">
            <p style="padding-left: 24px; margin-bottom: 0px; margin-top: 8px;"> 
              <a href="https://www.iubenda.com/termini-e-condizioni/10266349" style="color: #ffffff;">Termini e Condizioni</a>
            </p>
          </ion-col>
        </ion-row>
        <ion-row style="margin-top: -8px;">
          <ion-col size="6">
          </ion-col>
          <ion-col size="6">
            <p style="padding-left: 24px; margin-bottom: 0px; cursor: pointer; margin-top: 8px;" (click)="toSpedizioniEResi()"> 
              <a style="color: #ffffff;"><strong>Spedizioni e resi</strong></a>
            </p>
          </ion-col>
        </ion-row>
        <ion-row style="margin-top: 24px;">
          <ion-col size="12" style="text-align: center;"><strong>© 2025 Emplace s.r.l.</strong> - P.IVA 16726231000</ion-col>
        </ion-row>
        <ion-row style="margin-top: 16px;">
          <ion-col size="12" style="text-align: center;">
            <img style="height: 30px;" src="assets/icon/payment_options/stripe_logo_white.png">
            <img style="margin-left: 4px; height: 30px;" src="assets/icon/payment_options/mastercard_symbol.png">
            <img style="margin-left: 4px; height: 16px; margin-bottom: 8px;" src="assets/icon/payment_options/Visa_Brandmark_White_RGB_2021.png">
            <img style="margin-left: 6px; height: 35px; margin-bottom: -1px;" src="assets/icon/payment_options/amex_logo.png">
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
</ion-content>
