import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, ParamMap, Router } from '@angular/router';
import { AlertController, IonContent, Platform, PopoverController } from '@ionic/angular';
import { Address, OrderDetail, UOIElement, VendorOrderItem, VOIElement } from 'src/app/shared/models/Ordini.models';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ConstantUtilsService } from 'src/app/shared/services/constant-utils.service';
import { UserOrderService } from 'src/app/shared/services/user-orders.service';
import { VendorOrderService } from 'src/app/shared/services/vendor-orders.service';
import { VendorShippingInstructionsPopover } from './vendor-shipping-instructions/vendor-shipping-instructions.component';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-vendor-order',
  templateUrl: './vendor-order.component.html',
  styleUrls: ['./vendor-order.component.scss'],
})
export class VendorOrderComponent implements OnInit {

  @ViewChild(IonContent, {static: false}) private content: IonContent;

  // order detail variables
  private paramMap: ParamMap;
  isFetching: boolean = true;
  error404: boolean = false;
  error: boolean = false;
  orderDetail: VendorOrderItem;
  customerShippingAddress: Address;

  // order items variables
  assistance: boolean = false;
  emailstring: string = 'mailto:assistenza@emplacestore.it?Subject=Richiesta di supporto venditore';
  email: string = 'assistenza@emplacestore.it';
  orderItems: VOIElement[];
  hasItems: boolean = false;
  orderItemsError = null;
  private orderID: string;

  allItemsShipped: boolean = true;
  allItemsDelivered: boolean = true;
  private pop: any;

  constructor(private vendorOrderService: VendorOrderService, private router: Router, private popoverController: PopoverController,
    private activatedRoute: ActivatedRoute, public constantUtils: ConstantUtilsService, public platform: Platform,
    private authService: AuthService, private alertController: AlertController, private popover: PopoverController,
    private logger: NGXLogger) { }

  ngOnInit() {
    this.logger.log('VendorOrderComponent: ngOnInit');
    
    this.activatedRoute.queryParams.subscribe(params => {
      // tutta la parte di elaborazione dei parametri è stata sposta al di fuori di qui 
      // per mantenere questa parte asincrona il più breve possibile (e per evitare problemi di lifecycle - maggiori dettagli di seguito).
      this.paramMap = this.activatedRoute.snapshot.paramMap;
    });
    this.isFetching = true;
  }

  ionViewWillEnter(){
    this.logger.log('VendorOrderComponent: ngOnInit');
    this.orderID = this.paramMap.get('orderId');
    this.logger.log('VendorOrderComponent: orderID ottenuto = '+this.orderID);

    this.allItemsShipped = true;
    this.allItemsDelivered = true;
    // Richiediamo il dettaglio dell'ordine
    // DOC: Valuto se aggiornare il token prima di richiedere i dati della pagina al backend
    // Se il token è scaduto, allora eseguo un refresh e poi chiedo i dati..
    if(this.authService.hasToRefreshBasicAuth()){
      this.authService.refreshBasicAuthToken().then(
        go => {
          this.fetchOrderDetail(this.orderID);
        })
    }else{
      // ..Altrimenti chiedo i dati senza eseguire il refresh
      this.fetchOrderDetail(this.orderID);
    }
  }

  fetchOrderDetail(orderId: string){
    this.vendorOrderService.fetchOrderDetail(orderId).subscribe(
      responseData => {
        this.logger.log('VendorOrderComponent: richiesta di dettaglio ordine ritorna:');
        this.logger.log(responseData);
        this.orderDetail = responseData;
        this.isFetching = false;

        this.emailstring = this.emailstring + ' [ordine '+this.orderDetail.id+']';

        if(this.orderDetail.indirizzo){
          this.customerShippingAddress = this.orderDetail.indirizzo;
          this.logger.log(this.customerShippingAddress);
        }else{
          this.logger.log('VendorOrderComponent: TERRIBILE ERRORE.. ordine senza indirizzo del cliente dove spedire gli elementi!');
        }

        // retrieving order elements
        this.orderItems = this.orderDetail.elementi_ordine
        if(this.orderItems != null && this.orderItems.length > 0){
          this.logger.log('VendorOrderComponent: order items: ');
          this.logger.log(this.orderItems);
          this.hasItems = true;
          for(let item of this.orderItems){
            item.assistance = false;
            if(item.stato=='In Preparazione' || item.stato=='Attesa Pagamento'){
              this.allItemsShipped = false;
              this.allItemsDelivered = false;
            }else if(item.stato=='Spedito'){
              this.allItemsDelivered = false;
            }

            // Managing choices... 
            this.logger.log('choice_id = '+item.choice_id); // DEBUG
            this.logger.log('choice_description = '+item.choice_description); // DEBUG
            if(item.choice_id && !item.choice_description){
              for(let opt of item.prodotto.options){
                for(let choice of opt.choices){
                  if(choice.id==item.choice_id){
                    item.choice_description = choice.descrizione;
                    this.logger.log('tipologia validazione = '+opt.validazione.tipologia_validazione); // DEBUG
                    if(opt.validazione.tipologia_validazione=='regex'){
                      item.option_description = opt.descrizione;
                    }
                    break;
                  }
                }
              }
            }
            this.logger.log('option_description after processing = '+item.option_description); // DEBUG
            this.logger.log('choice_description after processing = '+item.choice_description); // DEBUG
          }
        }else{
          this.logger.log('VendorOrderComponent: order items NULL or with size = zero ');
          this.hasItems = false;
        }
      },
      error => {
        this.logger.log('VendorOrderComponent: ERROR retrieved = '+error.status);
        this.isFetching = false;
        if(error.status == 404){
          this.error404 = true;
        }else{
          // In molti degli altri casi non dovremmo arrivare qui ma esser bloccati dalla guard
          this.logger.log('Errore inatteso: '+error.status);
          this.error = true;
        }
      }
    );
  }

  manageOrderNotFound(){
    this.logger.log('VendorOrderComponent: ERROR 404 manager called ');
    // TODO:
    //this.router.navigate(['user/buyer/orders']);
  }

  onReloadOrderDetail(){
    this.logger.log('VendorOrderComponent: onReloadOrderDetail called');
    this.isFetching = true;
    this.error = false;
    this.error404 = false;
    this.ionViewWillEnter();
  }

  requestAssistance(id: string){
    this.logger.log('VendorOrderComponent: requestAssistance called');
    for(let item of this.orderItems){
      if(item.id == id){
        item.assistance = true;
      }
    }
  }

  enoughAssistance(id: string){
    this.logger.log('VendorOrderComponent: enoughAssistance called');
    for(let item of this.orderItems){
      if(item.id == id){
        item.assistance = false;
      }
    }
  }

  toContactAdmin(){
    this.logger.log('VendorOrderComponent: toContactAdmin called');
    // TODO:
  }

  toContactVendor(){
    this.logger.log('VendorOrderComponent: toContactVendor called');
    // TODO:
  }

  toProductDetail(id: string){
    this.logger.log('VendorOrderComponent: toProductDetail called with order item ID = '+id);
    //let navigationExtras: NavigationExtras = { state: { from: 'buyer-order' } };  // NO MORE USED
    this.router.navigate(['products/' + id + '/detail/']/*, navigationExtras*/);
  }

  toHome(){
    this.logger.log('VendorOrderComponent: toHome() called');
    this.router.navigateByUrl("home");
  }

  toShippingForm($event, index: number){
    this.logger.log('VendorOrderComponent: toShippingForm called with index = '+index);
    if(index!=null && index>=0){
      // da inserire come orderID se vogliamo renderlo specifico per item ***
      var ID = this.orderItems[index].id; // ***
      var enable_btn = true;
      if(this.orderItems[index].stato=='Spedito' || this.orderItems[index].stato=='In Preparazione'){
        enable_btn = true;
      }else{
        enable_btn = false;
        this.logger.log('VendorOrderComponent: toShippingForm called with enable_btn = '+enable_btn);
      }

      let navigationExtras: NavigationExtras = { 
        state: { 
          orderID: this.orderID,
          productID: this.orderItems[index].prodotto.id,
          tracking: this.orderItems[index].codice_tracking,
          telefono: this.orderItems[index].telefono,
          prefisso: this.orderItems[index].prefisso,
          trasportatore: this.orderItems[index].shipping_dealer,
          indirizzo_cliente: this.customerShippingAddress.indirizzo,
          cap_cliente: this.customerShippingAddress.cap,
          citta_cliente: this.customerShippingAddress.città,
          paese_cliente: this.customerShippingAddress.paese,
          provincia_cliente: this.customerShippingAddress.provincia,
          nome_cliente: this.orderDetail.nome,
          cognome_cliente: this.orderDetail.cognome,
          telefono_cliente: this.orderDetail.telefono,
          note_cliente: this.orderDetail.note,
          civico_cliente: this.customerShippingAddress.civico,
          enable_btn: enable_btn
        } 
      };
      this.router.navigate(['myprofile/order/vendor-order/'+this.orderID+'/shipping-info'], navigationExtras);
    }
  }

  toDeliveryPopup(){
    this.logger.log('VendorOrderComponent: toDeliveryPopup() called...');
    this.openDeliveryAlert(null,null);
  }

  async openDeliveryAlert(header: string, text: string){
    if(!text || !header){
      header = 'Stato di consegna dell\'ordine'
      text = 'Se l\'ordine è stato consegnato, conferma. Ricorda che indicazioni mendaci pregiudicheranno la tua reputazione nel marketplace. Se il tuo prodotto non è stato ancora consegnato, annulla.';
    }
    const alert = await this.alertController.create({
      header: 'Consegnato?',
      cssClass: 'alert-info-popup-style',
      subHeader: header,
      message: text,
      buttons: [
        {
          text: 'Conferma',
          cssClass: 'alert-info-popup-btn-style',
          handler: () => { 
            // Richiediamo il dettaglio dell'ordine
            // DOC: Valuto se aggiornare il token prima di richiedere i dati della pagina al backend
            // Se il token è scaduto, allora eseguo un refresh e poi chiedo i dati..
            if(this.authService.hasToRefreshBasicAuth()){
              this.authService.refreshBasicAuthToken().then(
                go => {
                  this.onDeliveryConfirmation();
                })
            }else{
              // ..Altrimenti chiedo i dati senza eseguire il refresh
              this.onDeliveryConfirmation();
            } 
          }
        },
        {
          text: 'Annulla',
          cssClass: 'alert-danger-popup-btn-style'
        }
      ]
    });
  
    await alert.present();
  }

  onDeliveryConfirmation(){
    this.logger.log('onDeliveryConfirmation() launched.....');
    this.vendorOrderService.deliverVendorOrder(this.orderID).then(
      res => {
        this.logger.log(res);
        this.ionViewWillEnter();
        if(res){
          // Solo se res = true allora la consegna è avvenuta con successo
          this.vendorOrderService.toBeUpdated = true;
        }
      },
      error => {
        this.logger.log(error);
      }
    )
  }

  shippingInfoPopup(){
    this.infoAlert('Hai spedito uno o più elementi dell\'ordine?', 'Se hai spedito un elemento di questo ordine, clicca sul tasto \'Spedisci\' e inserisci le informazioni di tracking per contrassegnare l\'elemento come \'Spedito\'.');
  }

  deliveryInfoPopup(){
    this.infoAlert('Hai consegnato uno o più elementi dell\'ordine?', 'Se hai consegnato un elemento di questo ordine, clicca sul link \"E\' stato consegnato?\" per contrassegnare l\'elemento come \"consegnato\".');
  }


  async infoAlert(header: string, text: string){
    if(!text || !header){
      header = 'Informazione'
      text = ' ';
    }
    const alert = await this.alertController.create({
      header: 'Stato dell\'ordine',
      cssClass: 'alert-info-popup-style',
      subHeader: header,
      message: text,
      buttons: [
        {
          text: 'OK',
          cssClass: 'alert-info-popup-btn-style'
        }
      ]
    });
  
    await alert.present();
  }


  toIstruzioni(){
    this.logger.log('VendorOrderComponent: toIstruzioni() called..');
    this.CreateInstructionsPopover(null);
  }

  async CreateInstructionsPopover(ev: any) {
    this.pop = await this.popover.create({
      component: VendorShippingInstructionsPopover,
      cssClass: 'info-popover-big-style-class',
      event: ev,
      translucent: true,
      componentProps: {
        /*"tracking": tracking,*/
      }
    });
    return await this.pop.present();
  }

}
