import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class BookService {

  constructor(private http: HttpClient, private logger: NGXLogger) {}
  
  private bookQueryURLWithISBNKey: string = 'https://www.googleapis.com/books/v1/volumes?q=isbn:';
  error = new Subject<string>();

  // HTTP GET
  fetchBookByISBN(isbn: string){
    const getURL = this.bookQueryURLWithISBNKey+isbn;
    this.logger.log('fetchBookByISBN getting to '+getURL)

    return this.http
    .get(
      getURL,
      {
        headers: new HttpHeaders({ 'Content-Type' : 'application/json' }),
        responseType: 'json'
      }
    )
    .pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        // Send to analytics server
        return throwError(errorRes);
      })
    );
  }

}
