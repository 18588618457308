<ion-card style="margin: 0px;">
    <ion-card-header (click)="onClose()" style="padding: 6px; padding-bottom: 16px">
        <ion-row style="padding: 0px;">
            <ion-col size="10" style="padding: 0px;">
                <ion-card-title color="sea" style="padding: 8px; font-size: 18px;"><strong>Informazioni di spedizione</strong></ion-card-title>
            </ion-col>
            <ion-col size="2" style="padding: 0px;">
                <ion-button style="float: right;" fill="clear">
                    <ion-icon color="danger" slot="icon-only" name="close-outline"></ion-icon>
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-card-header>
    <ion-card-content style="padding: 0px;">
        <ion-item lines="none">
            <ion-text color="sea">Codice di tracking: </ion-text>
            <ion-text *ngIf="tracking && tracking!=' '" style="padding-left: 4px;"><strong>{{tracking}}</strong></ion-text>
            <ion-text *ngIf="!tracking || tracking==' '" style="padding-left: 4px;">Non Inserito</ion-text>
        </ion-item>
        <ion-item lines="none">
            <ion-text color="sea">Corriere: </ion-text>
            <ion-text *ngIf="corriere && corriere!=' '" style="padding-left: 4px;">{{corriere}}</ion-text>
            <ion-text *ngIf="!corriere || corriere==' '" style="padding-left: 4px;">Non Inserito</ion-text>
        </ion-item>
        <ion-item lines="none" style="margin-bottom: 18px;">
            <ion-text color="sea">Telefono: </ion-text>
            <ion-text *ngIf="telefono && telefono!=' '" style="padding-left: 4px;">{{prefisso}} {{telefono}}</ion-text>
            <ion-text *ngIf="!telefono || telefono==' '" style="padding-left: 4px;">Non Inserito</ion-text>
        </ion-item>
    </ion-card-content>
</ion-card>