import {Injectable} from "@angular/core";


@Injectable()
export class AddressUtilsService {
  
    provincia_regione_matrix = [
        'ROMA','Lazio', 
        'VITERBO','Lazio', 
        'RIETI','Lazio', 
        'FROSINONE','Lazio',  
        'LATINA','Lazio', 

        'TERNI','Umbria', 
        'PERUGIA','Umbria', 

        'SASSARI','Sardegna', 
        'NUORO','Sardegna', 
        'CAGLIARI','Sardegna', 
        'ORISTANO','Sardegna', 

        'AOSTA','Valle D\'Aosta', 

        'TORINO','Piemonte', 
        'CUNEO','Piemonte', 
        'VERCELLI','Piemonte', 
        'BIELLA','Piemonte',  
        'ASTI','Piemonte', 
        'ALESSANDRIA','Piemonte',
        'NOVARA','Piemonte',  
        'VERBANIA','Piemonte', 


        'GENOVA','Liguria',  
        'SAVONA','Liguria',  
        'IMPERIA','Liguria',  
        'LA SPEZIA','Liguria', 

        'MILANO','Lombardia',   
        'VARESE','Lombardia',  
        'COMO','Lombardia',  
        'SONDRIO','Lombardia', 
        'LECCO','Lombardia', 
        'BERGAMO','Lombardia',  
        'BRESCIA','Lombardia', 
        'CREMONA','Lombardia',  
        'LODI','Lombardia',  
        'PAVIA','Lombardia', 
        'MONZA E BRIANZA','Lombardia', 
        'MANTOVA','Lombardia', 

        'VENEZIA','Veneto', 
        'TREVISO','Veneto', 
        'BELLUNO','Veneto', 
        'PADOVA','Veneto', 
        'VICENZA','Veneto', 
        'VERONA','Veneto', 
        'ROVIGO','Veneto', 

        'UDINE', 'Friuli Venezia Giulia',
        'PORDENONE', 'Friuli Venezia Giulia',  
        'TRIESTE', 'Friuli Venezia Giulia', 
        'GORIZIA', 'Friuli Venezia Giulia',  

        'TRENTO', 'Trentino Alto Adige', 
        'BOLZANO', 'Trentino Alto Adige', 

        'BOLOGNA','Emilia Romagna', 
        'MODENA','Emilia Romagna', 
        'REGGIO EMILIA','Emilia Romagna', 
        'PARMA','Emilia Romagna', 
        'FERRARA','Emilia Romagna', 
        'FORLI','Emilia Romagna',  
        'RIMINI','Emilia Romagna', 
        'RAVENNA','Emilia Romagna',  
        'CESENA','Emilia Romagna',
        'PIACENZA','Emilia Romagna', 

        'FIRENZE','Toscana',  
        'PISTOIA','Toscana',  
        'AREZZO',' Toscana', 
        'SIENA','Toscana',  
        'MASSA','Toscana',  
        'LUCCA','Toscana',  
        'PISA',' Toscana', 
        'LIVORNO',' Toscana', 
        'GROSSETO','Toscana',  
        'PRATO','Toscana',  

        'ANCONA',' Marche', 
        'PESARO','Marche',  
        'MACERATA','Marche',  
        'ASCOLI PICENO','Marche',  
        'FERMO','Marche',  

        'TERAMO','Abbruzzo', 
        'PESCARA','Abbruzzo', 
        'CHIETI','Abbruzzo', 
        'L\'AQUILA','Abbruzzo',  

        'BARI',' Puglia', 
        'FOGGIA','Puglia', 
        'BRINDISI', 'Puglia', 
        'LECCE','Puglia', 
        'TARANTO','Puglia', 
        'BARLETTA','Puglia',
        'ANDRIA','Puglia',
        'TRANI','Puglia',

        'NAPOLI','Campania',  
        'CASERTA','Campania', 
        'BENEVENTO','Campania',  
        'AVELLINO','Campania', 
        'SALERNO','Campania',  

        'POTENZA','Basilicata', 
        'MATERA','Basilicata', 

        'CAMPOBASSO','Molise', 
        'ISERNIA','Molise', 

        'COSENZA','Calabria',  
        'CATANZARO','Calabria',
        'CROTONE','Calabria', 
        'REGGIO CALABRIA','Calabria',
        'VIBO VALENTIA','Calabria',  

        'PALERMO','Sicilia', 
        'TRAPANI','Sicilia', 
        'AGRIGENTO','Sicilia',  
        'CALTANISSETTA','Sicilia',  
        'ENNA', 'Sicilia',
        'CATANIA','Sicilia',  
        'SIRACUSA','Sicilia', 
        'RAGUSA','Sicilia',  
        'MESSINA','Sicilia' 
    ]

    province_array = [
        'AGRIGENTO',
        'ALESSANDRIA',
        'ANCONA',
        'ANDRIA',
        'AOSTA',
        'AREZZO',
        'ASCOLI PICENO',
        'ASTI',
        'AVELLINO',
        'BARI',
        'BARLETTA',
        'BENEVENTO', 
        'BELLUNO',
        'BERGAMO', 
        'BIELLA', 
        'BOLOGNA',
        'BOLZANO',
        'BRESCIA',
        'BRINDISI',
        'CAGLIARI',
        'CALTANISSETTA',
        'CAMPOBASSO',
        'CASERTA',
        'CATANIA',
        'CATANZARO',
        'CESENA',
        'CHIETI',
        'COMO',
        'COSENZA',
        'CREMONA',
        'CROTONE',
        'CUNEO',
        'ENNA',
        'FERMO',
        'FERRARA',
        'FIRENZE',
        'FOGGIA',
        'FORLI',
        'FROSINONE',
        'GENOVA',
        'GORIZIA',
        'GROSSETO',
        'IMPERIA',
        'ISERNIA',
        'L\'AQUILA',
        'LA SPEZIA',
        'LATINA',
        'LECCE',
        'LECCO',  
        'LIVORNO',  
        'LODI',
        'LUCCA',
        'MACERATA',
        'MANTOVA',
        'MASSA',
        'MATERA',
        'MESSINA',
        'MILANO',
        'MODENA',
        'MONZA E BRIANZA',
        'NAPOLI',
        'NOVARA',
        'NUORO',
        'ORISTANO',
        'PADOVA',
        'PALERMO',
        'PARMA',
        'PAVIA',
        'PERUGIA',
        'PESARO',
        'PESCARA',
        'PIACENZA',
        'PISA',
        'PISTOIA',
        'PORDENONE',
        'POTENZA',
        'PRATO',
        'RAGUSA',
        'RAVENNA',
        'REGGIO EMILIA',
        'REGGIO CALABRIA',
        'RIETI',
        'RIMINI',
        'ROMA',  
        'ROVIGO',
        'SALERNO',
        'SASSARI',
        'SAVONA',
        'SIENA',
        'SIRACUSA',
        'SONDRIO',
        'TARANTO',
        'TERAMO',
        'TERNI',
        'TORINO',
        'TRANI',
        'TRAPANI',
        'TRENTO',
        'TREVISO',
        'TRIESTE',
        'UDINE',
        'VARESE',
        'VENEZIA',
        'VERBANIA',
        'VERCELLI',
        'VERONA',
        'VIBO VALENTIA',
        'VICENZA',
        'VITERBO'
    ]

    constructor() {}

    getRegioneTramiteProvincia(provincia: string){
        const provincia_UC = provincia.toUpperCase();
        var index = 0;
        while(index < this.provincia_regione_matrix.length){
            if(this.provincia_regione_matrix[index]==provincia_UC){
                return this.provincia_regione_matrix[index+1];
            }
            index = index + 2;
        }
        return null;
    }

}