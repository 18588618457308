import {Component, HostListener, Input, OnInit} from "@angular/core";
import {ActivatedRoute, NavigationExtras, Router} from "@angular/router";
import { Platform } from "@ionic/angular";
import { NGXLogger } from "ngx-logger";
import {Subscription} from "rxjs";
import { CategoriaItem } from "src/app/shared/models/Categorie.model";
import {Prodotti, ProdottoItem} from "src/app/shared/models/Prodotti.model";
import {SezioneHome, TIPO_SEZIONE_CATEGORIE, TIPO_SEZIONE_PRODOTTI, TIPO_SEZIONE_PRODOTTI_CATEGORIA} from "src/app/shared/models/SezioneHome.model";
import { CategoryProductsService } from 'src/app/shared/services/categoryProducts.service';
import { ConstantUtilsService } from "src/app/shared/services/constant-utils.service";
import {GetProdottiService} from "src/app/shared/services/getProdotti.service";
import { GetCategorieService } from "src/app/shared/services/getRootCategories.service";

@Component({selector: "app-sezione-home", templateUrl: "./sezione-home.component.html", styleUrls: ["./sezione-home.component.scss"], providers: [GetProdottiService]})
export class SezioneHomeComponent implements OnInit {
  isFetching = false;
  errorSubscription: Subscription;
  @Input()sezione: SezioneHome;
  private prodotti: ProdottoItem[];
  prodottiToBeShown: ProdottoItem[];
  categorie: CategoriaItem[];
  errorMsg: string;
  private type: string;
  private ordering: string;
  private isFilter: boolean = false;

  // Number of categories shown with three and four columns
  two_col_categories: number = 4;
  three_col_categories: number = 6;
  four_col_categories: number = 8;
  six_col_categories: number = 12;
  innerWidth: any;
  SCREEN_SIZE_XS_SM: number = 576;
  SCREEN_SIZE_MD: number = 768;
  SCREEN_SIZE_SM_MD: number = 768;
  SCREEN_SIZE_MD_XL: number = 992;
  SCREEN_SIZE_LG_XL: number = 992;
  private MAX_HOME_SECTION_CONTENT: number = 12; // Max di prodotti per HomeSection (con 2 righe da 6 elementi)

  constructor(private activatedRoute : ActivatedRoute, private getProdottiService : GetProdottiService, 
    private categoryProductsService: CategoryProductsService, private router: Router, private categoryService: GetCategorieService,
    public constantUtilsService: ConstantUtilsService, public platform: Platform, private logger: NGXLogger) {}

  ngOnInit() {
    this.errorSubscription = this.getProdottiService.error.subscribe((errorMessage) => {
      this.errorMsg = errorMessage;
    });
    // Screen Size iniziale
    this.innerWidth = window.innerWidth;

    if(this.sezione.tipo==TIPO_SEZIONE_CATEGORIE){
      this.getCategories();
    }else{
      this.getProdotti();
    }
  }

  // Tiene conto dei cambi di screen size
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    //this.logger.log('Sezione-Home: cambio di screen size -> '+this.innerWidth);  // DEBUG
    this.screenManager();
  }

  private screenManager(){
    if(this.prodotti){
      // Gestisco il cambio di dimensione dello schermo 
      if(this.innerWidth<this.SCREEN_SIZE_XS_SM){
        this.prodottiToBeShown = this.prodotti.slice(0,4);
      }else if(this.innerWidth>=this.SCREEN_SIZE_XS_SM && this.innerWidth<this.SCREEN_SIZE_SM_MD){
        this.prodottiToBeShown = this.prodotti.slice(0,6);
      }else if(this.innerWidth>=this.SCREEN_SIZE_SM_MD && this.innerWidth<this.SCREEN_SIZE_MD_XL){
        this.prodottiToBeShown = this.prodotti.slice(0,8);
      }else{
        this.prodottiToBeShown = this.prodotti.slice(0,8);
      }
    }
  }

  private getProdotti() {
    this.isFetching = true;

    let productsObservable = undefined;
    if(this.sezione.tipo === TIPO_SEZIONE_PRODOTTI){
      this.logger.log('Sezione-Home: richiedendo prodotti con la url = '+this.sezione.url+'&limit='+this.MAX_HOME_SECTION_CONTENT);
      productsObservable = this.getProdottiService.fetchProductsByURL(this.sezione.url+'&limit='+this.MAX_HOME_SECTION_CONTENT);
    }
    else if(this.sezione.tipo === TIPO_SEZIONE_PRODOTTI_CATEGORIA){
      this.logger.log('Sezione-Home: richiedendo prodotti (x categoria) con la url = '+this.sezione.url+'&limit='+this.MAX_HOME_SECTION_CONTENT);
      productsObservable = this.categoryProductsService.fetchProductsByURL(this.sezione.url+'&limit='+this.MAX_HOME_SECTION_CONTENT);
    }
    else{
      console.error("Unexpected homeSection type in section", this.sezione)
      return;
    }

    productsObservable.subscribe((response : Prodotti) => {
      this.isFetching = false;
      this.prodotti = response.results;
      this.screenManager();
    }, (error) => {
      this.logger.log("fetchProducts returned an error:", error);
      this.isFetching = false;
      this.errorMsg = error.message;
    });
  }

  private getCategories(){
    this.logger.log('Sezione-Home: getCategories() initiated');
    
    this.isFetching = true;

    let categoriesObservable = undefined;
    if(this.sezione.tipo === TIPO_SEZIONE_CATEGORIE){
      this.logger.log('HomeSection: Richiedendo categorie con url = '+this.sezione.url+'?limit='+this.MAX_HOME_SECTION_CONTENT);
      categoriesObservable = this.categoryService.fetchCategories(this.sezione.url+'?limit='+this.MAX_HOME_SECTION_CONTENT);
    }
    else{
      console.error("Unexpected homeSection type in section", this.sezione)
      return;
    }

    categoriesObservable.subscribe((response) => {
      this.isFetching = false;
      this.categorie = response.results;
      this.logger.log(this.categorie);
    }, (error) => {
      this.logger.log("fetchCategories returned an error:", error);
      this.isFetching = false;
      this.errorMsg = error.message;
    });
  }

  ngOnDestroy() {
    this.errorSubscription.unsubscribe();
  }

  toPaginaProdotti(){
    this.logger.log('SezioneHome: navigating to PaginaProdotti');
    if(this.sezione.url.includes('filter/?filter_key')){
      // Per le richieste GET complesse, che solitamente indirizziamo tramite il filtro in Prodotti
      this.type = 'filter/?filter_key' + this.sezione.url.split('filter/?filter_key',2)[1];
      this.isFilter = true;
      this.logger.log('type (filtro) inviato per la sezione-home = '+this.type);
    }else if(this.sezione.url.includes('punti_foglia')){
      this.type = 'punti_foglia';
    }else if(this.sezione.url.includes('media_recensioni')){
      this.type = 'media_recensioni';
    }else if(this.sezione.url.includes('tagsost')){
      this.type = 'tagsost';
      this.ordering = 'desc';
    }else if(this.sezione.url.includes('top_venduti')){
      this.type = 'top_venduti';
      this.ordering = 'desc';
    }else if(this.sezione.url.includes('sconto')){
      this.type = 'sconto';
    }else if(this.sezione.url.includes('data_inserimento')){
      this.type = 'data_inserimento';
      this.ordering = 'desc';
    }else{
      this.logger.log('SezioneHome: toPaginaProdotti chiuso per url errato');
      return;
    }

    let navigationExtras: NavigationExtras;
    if(!this.ordering){
      navigationExtras = { state: { name: this.sezione.nome, type: this.type, index: null, filter: this.isFilter } };
    }else{
      navigationExtras = { state: { name: this.sezione.nome, type: this.type, ordering: this.ordering, index: null, filter: this.isFilter } };
    }
    let destinationURL = '/products';
    this.router.navigate([destinationURL], navigationExtras);
  }

  toCategoryPage(){
    this.logger.log('SezioneHome: toCategoryPage()');
    this.router.navigate(['/categories']);
  }

  onSelectCategory(id: number, nome: string){
    this.logger.log('SezioneHome: selezionata la categoria = '+nome);
    let navigationExtras: NavigationExtras = { state: { name: nome } };
    let destinationURL = '/products/category/'+id;
    this.router.navigate([destinationURL], navigationExtras);
  }
}
