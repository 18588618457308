<ion-card style="margin: 0px;">
    <ion-card-header>
      <div style="margin-top: 18px;">
        <p style="text-align: center; color: var(--ion-color-primary); font-size: 18px;"><strong>Aggiungi Libro</strong>
          <ion-icon name="book" color="primary" style="height: 30px; width: 30px; margin-bottom: -10px; margin-left: 10px;"></ion-icon>
        </p>
      </div>
    </ion-card-header>
    <ion-card-content style="padding-left: 8px; padding-right: 8px;">
    
      <ion-grid>
        <ion-row>
          <ion-col size="12">
            <p style="font-size: 14px; text-align: left;">
              Aggiungi un <strong>libro usato</strong> in vendita nel tuo mercatino.
            </p>
            <!-- Testo SENZA SCANSIONA ### -->
            <p style="font-size: 14px; text-align: left;">
              Digita il <strong>codice ISBN</strong> per avviare la compilazione automatica delle informazioni che lo descrivono. L'inserimento sarà veloce e intuitivo!
            </p> <!-- ### -->
            <!-- TODO: Testo con Scansiona abilitato -->
            <!--<p style="font-size: 14px; text-align: left;">
              Digita il <strong>codice ISBN</strong><i> oppure</i> clicca su <strong>Scansiona</strong> per scattare una foto al codice sul retro del tuo libro e avviare così la compilazione automatica delle informazioni che lo descrivono.
            </p>-->
            <p style="font-size: 14px; text-align: left;">
              Potrai rivedere, modificare e confermare tali informazioni in seguito, prima che il libro venga inserito nel tuo profilo venditore.
            </p>
          </ion-col>
          <ion-col size="12"></ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12" *ngIf="!scannerTriggered">
            <ion-item lines="none">
                <ion-label><strong>Codice ISBN</strong></ion-label>
            </ion-item>
            <ion-item>
                <ion-input aria-label="ISBN" type="text" #isbn (ionInput)="onChangeTextISBN()" [(ngModel)]="isbnCode"
                placeholder="Digita il codice ISBN" maxlength="{{ISBN_MAX}}"></ion-input>
            </ion-item>
          </ion-col>

          <!-- TODO: SCANSIONA ### -->
          <!-- 
          <ion-col size="12" style="margin-top: 12px;" *ngIf="!missingData && !requestError">
            <ion-text style="font-size: 14px; margin-left: 16px;" *ngIf="!scannerTriggered">Oppure   </ion-text>
            <p *ngIf="scannerTriggered" (click)="onCloseCamera()" style="color: var(--ion-color-danger); text-align: center; cursor: pointer;"><u>Chiudi</u></p>
            <ion-button color="darksea" (click)="onPhotoISBN()" style="margin-left: 16px; margin-top: -4px;" *ngIf="!scannerTriggered">
                <ion-icon name="camera" style="margin-right: 12px;"></ion-icon>
                Scansiona
            </ion-button>

            <zxing-scanner *ngIf="scannerTriggered" (scanSuccess)="onCodeResult($event)" [torch]="torchEnabled" [(device)]="currentDevice"
            [formats]="formatsEnabled" [tryHarder]="tryHarder" (permissionResponse)="onHasPermission($event)"
            (camerasFound)="onCamerasFound($event)" (torchCompatible)="onTorchCompatible($event)"></zxing-scanner>
          </ion-col> -->
          <!-- ### -->

          <!-- TODO: Stato del libro (da abilitare insieme a Scansiona) @@@ -->
          <!--
          <ion-col size="12" style="margin-top: 12px;">
            <p style="font-size: 14px; text-align: center;">Stato del libro: </p>
            <ion-row>
              <ion-col size="6" style="text-align: right;">
                <ion-button color="sea" (click)="onChooseStatus(0)" style="margin-left: 16px;" *ngIf="!comeNuovo">
                  Come Nuovo
                </ion-button>
                <ion-button color="darksea" (click)="onChooseStatus(0)" style="margin-left: 16px;" *ngIf="comeNuovo">
                  Come Nuovo
                </ion-button>
              </ion-col>
              <ion-col size="6" style="text-align: left;">
                <ion-button color="sea" (click)="onChooseStatus(1)" *ngIf="!ottimo">
                  Ottimo
                </ion-button>
                <ion-button color="darksea" (click)="onChooseStatus(1)" *ngIf="ottimo">
                  Ottimo
                </ion-button>
              </ion-col>
              <ion-col size="6" style="text-align: right;">
                <ion-button color="sea" (click)="onChooseStatus(2)" style="margin-left: 16px;" *ngIf="!buono">
                  Buono
                </ion-button>
                <ion-button color="darksea" (click)="onChooseStatus(2)" style="margin-left: 16px;" *ngIf="buono">
                  Buono
                </ion-button>
              </ion-col>
              <ion-col size="6" style="text-align: left;">
                <ion-button color="sea" (click)="onChooseStatus(3)" *ngIf="!usurato">
                  Usurato
                </ion-button>
                <ion-button color="darksea" (click)="onChooseStatus(3)" *ngIf="usurato">
                  Usurato
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-col> -->
          <!-- @@@ -->

          <ion-col size="12" style="text-align: center; margin-top: 24px;" *ngIf="missingData || requestError">
            <p style="color: var(--ion-color-danger);">Arg! Servizio temporaneamente non disponibile...</p>
          </ion-col>

          <ion-col size="12" style="text-align: center; margin-top: 24px;" *ngIf="notValidISBN">
            <p style="color: var(--ion-color-danger);">Inserisci un codice ISBN oppure scansionalo con la fotocamera!</p>
          </ion-col>
          
          <ion-col size="12" style="text-align: center; margin-top: 24px;" *ngIf="!missingData && !requestError">
            <ion-button color="danger" (click)="onClose()">Annulla</ion-button>
            <ion-button color="primary" (click)="onSendISBN()" style="margin-left: 8px; width: 85px;">Invia</ion-button>
          </ion-col>

          <ion-col size="12" style="text-align: center; margin-top: 24px;" *ngIf="missingData || requestError">
            <ion-button color="danger" (click)="onClose()">Annulla</ion-button>
            <ion-button color="primary" (click)="onRetry()" style="margin-left: 8px; width: 85px;">Riprova</ion-button>
          </ion-col>
          
          <ion-col size="12" *ngIf="isFetching" style="text-align: center;">
            <ion-spinner name="bubbles"  *ngIf="isFetching"></ion-spinner>
          </ion-col>
        </ion-row>
      </ion-grid>
  
    </ion-card-content>
  </ion-card>