import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpEventType
} from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { Subject, throwError, Observable } from 'rxjs';
import { ShoppingCartItemsService } from './shopping-cart-items.service';
import { NewShoppingCartItem, ShoppingCart, ShoppingCartUpdate } from '../models/ShoppingCart.models';
// import { Plugins } from '@capacitor/core';
import { ProdottoItem } from '../models/Prodotti.model';

import { Preferences } from '@capacitor/preferences';
import { AuthService } from './auth.service';
import { UserService } from './User.service';
import { NGXLogger } from 'ngx-logger';
import { ProdItemChoice, ValidationRule } from '../models/Opzioni.models';


@Injectable()
export class ShoppingCartManagerService {

    isChanged: boolean = true;
    cartBadge: Number = 0;
    shoppingCartResArray: any = [];
    zombie: boolean = false;
  
    constructor(private http: HttpClient, private shoppingCartItemsService: ShoppingCartItemsService, 
        private logger: NGXLogger) {}

    isChangedShoppingCart(){
        this.logger.log('ShoppingCartManagerService: isChangedShoppingCart method returns: '+this.isChanged);
        return this.isChanged;
    }

    getShoppingCartBadge(caller: string){
        if(this.isChangedShoppingCart()){
            return this.shoppingCartItemsService.fetchItemsByURL().pipe(
                map(responseData => {
                    this.shoppingCartResArray = <ShoppingCart>responseData;
                    this.logger.log('ShoppingCartManagerService: ['+caller+'] shopping cart GET retrieved something... ');
                    this.logger.log(this.shoppingCartResArray);
                    if(this.shoppingCartResArray==null){
                        this.cartBadge = 0;
                    }else{
                        this.cartBadge = this.shoppingCartResArray.length;
                    }
                    this.logger.log('ShoppingCartManagerService: ['+caller+'] new cartBadge announced = '+this.cartBadge);

                    // Controllo se ci sono ordini zombie nel carrello
                    if(this.shoppingCartResArray && this.shoppingCartResArray.length>=1 &&
                        this.shoppingCartResArray[this.shoppingCartResArray.length-1].ordini_non_completati){
                        // Allora riduco di 1 il totale di elementi nel carrello (altrimenti verrebbe contata anche la cella ordini_non_completati)
                        this.cartBadge = this.cartBadge.valueOf() - 1;
                        this.zombie = true;
                    }
                    
                    this.isChanged = false;
                    return this.cartBadge;
                }),
                catchError(errorRes => {
                    // Send to analytics server
                    this.logger.log('ShoppingCartManagerService: ['+caller+'] shopping cart GET error');
                    this.cartBadge = 0;
                    return throwError(errorRes);
                })
            );
        }else{
            this.logger.log('ShoppingCartManagerService: cartBadge already updated');
            return new Observable<Number>((observer) => { observer.next(this.cartBadge) });
        }
    }

    getShoppingCartContent(caller: string){
        return this.shoppingCartItemsService.fetchItemsByURL().pipe(
            map(responseData => {
                this.shoppingCartResArray = <ShoppingCart>responseData;
                this.logger.log('ShoppingCartManagerService: ['+caller+'] shopping cart GET retrieved something... ');
                this.logger.log(this.shoppingCartResArray);
                if(this.shoppingCartResArray==null){
                    this.cartBadge = 0;
                }else{
                    this.cartBadge = this.shoppingCartResArray.length;
                }
                this.logger.log('ShoppingCartManagerService: ['+caller+'] new cartBadge announced = '+this.cartBadge);

                // Controllo se ci sono ordini zombie nel carrello
                if(this.shoppingCartResArray && this.shoppingCartResArray.length>=1 &&
                    this.shoppingCartResArray[this.shoppingCartResArray.length-1].ordini_non_completati){
                    // Allora riduco di 1 il totale di elementi nel carrello (altrimenti verrebbe contata anche la cella ordini_non_completati)
                    this.cartBadge = this.cartBadge.valueOf() - 1;
                    this.zombie = true;
                }
                
                return this.shoppingCartResArray;
            }),
            catchError(errorRes => {
                // Send to analytics server
                this.logger.log('ShoppingCartManagerService: ['+caller+'] shopping cart GET error');
                this.cartBadge = 0;
                return throwError(errorRes);
            })
        );
    }

    insertIntoShoppingCart(caller: string, prodotto: ProdottoItem, choice: ProdItemChoice, descrizioneOpzione:string, validazione: ValidationRule){
        this.logger.log('ShoppingCartManagerService: ['+caller+'] utente loggato, inserimento nel carrello remoto...');
        return this.shoppingCartItemsService.createItemPost(prodotto, choice, descrizioneOpzione, validazione).pipe(
            map(responseData => {
                this.logger.log(responseData);
                this.isChanged = true;
                return this.cartBadge;
            }),
            catchError(errorRes => {
                // Send to analytics server
                this.logger.log('ShoppingCartManagerService: ['+caller+'] shopping cart GET error');
                
                return throwError(errorRes);
            })
        );
    }

    updateShoppingCart(caller: string, cart: NewShoppingCartItem[]){
        this.logger.log('ShoppingCartManagerService: ['+caller+'] utente loggato, aggiornamento del carrello remoto...');
        return this.shoppingCartItemsService.createItemsPost(cart).pipe(
            map(responseData => {
                this.logger.log(responseData);
                
                var res_cart = <ShoppingCartUpdate[]> responseData.body;
                if(res_cart!=null && res_cart.length>0){
                    // X DEBUG ->
                    /*for(let item of res_cart){ 
                        this.logger.log('nome = '+item.prodotto);
                    }*/
                    this.cartBadge = res_cart.length;
                    return this.cartBadge;
                }else if(res_cart!=null && res_cart.length==0){
                    this.cartBadge = 0;
                    return this.cartBadge;
                }else{
                    return 0;
                }
            }),
            catchError(errorRes => {
                // Send to analytics server
                this.logger.log('ShoppingCartManagerService: ['+caller+'] shopping cart GET error');
                
                return throwError(errorRes);
            })
        );
    }

    mergeRemoteAndLocalShoppingCart(caller: string, local_cart: any[]){
        this.logger.log('ShoppingCartManagerService: ['+caller+'] utente loggato, fusione dei due carrelli...');
        
        // Gestione delle opzioni
        for(let c of local_cart){
            if(c['options'] && c['options'][0] && c['options'][0]['choices'] && c['options'][0]['choices'][0]){
                if(!c['choice_id']){
                    c['choice_id'] = c['options'][0]['choices'][0]['id'];
                }
            }
        }

        //this.logger.log('LOCAL CART = '+JSON.stringify(local_cart));   // DEBUG
        var res = this.shoppingCartItemsService.updateItemsPost(local_cart);
        if(res){
            return res.pipe(
                map(responseData => {
                    this.logger.log(responseData);
                    
                    if(responseData.status == 201){
                        // Solo se è stato aggiunto almeno un item sul carrello remoto, allora pulisco il carrello locale
                        this.localCart_removeItems(caller, local_cart);
                    }

                    return this.cartBadge;
                }),
                catchError(errorRes => {
                    // Send to analytics server
                    this.logger.log('ShoppingCartManagerService: ['+caller+'] shopping cart GET error');
                    
                    return throwError(errorRes);
                })
            );
        }else{
            this.logger.log('ShoppingCartManagerService: Errore nel aggiornamento del carrello');
            return new Observable<Number>((observer) => { observer.next(this.cartBadge) });
        }
    }

    shoppingCartChange(){
        this.isChanged = true;
    }

    resetShoppingCart(){
        this.logger.log('ShoppingCartManager: cart badge reset !!!');
        this.cartBadge = 0;
    }

    async localCart_storageKeys(caller: string) {
        const { keys } = await Preferences.keys();
        var cart_keys = [];
        for(let key of keys){
          this.logger.log('key read (only) within local storage: '+key);
          if(key.substring(0,13)=='emplace-cart-'){
            cart_keys.push(key);
          }
        }
        this.cartBadge = cart_keys.length;
        this.logger.log('ShoppingCartManagerService: ['+caller+'] new cartBadge announced = '+this.cartBadge);
        
        return this.cartBadge;
      }
    
      async localCart_putObject(key: string, name: string, image: string, quantità: number, prezzo_vetrina: string, prezzo_trasporto: string, 
        venditoreId: string, venditore_nome: string, quantita_disponibile: number, options: any) {
        if(name!=null && image!=null && quantità>0 && prezzo_vetrina!= null){
          this.logger.log('putObject di un item nel carrello locale..');
          this.logger.log('key: '+key+', name: '+name+', image: '+image+', quantità: '+quantità+', prezzo_vetrina: '+prezzo_vetrina);
          this.logger.log('prezzo trasporto: '+prezzo_trasporto+', venditoreId: '+venditoreId);
          this.logger.log('quantità disponibile: '+quantita_disponibile);
          this.logger.log('options: '+JSON.stringify(options));
    
          return this.localCart_getObject('emplace-cart-'+key).then(
            value => {
                if(value==null){
                    this.logger.log('putObject: inserimento di un nuovo item nel carrello locale..');
                    // Inserisco un nuovo item nel carrello
                    this.localCart_updateObject(key,name,image, 1 ,prezzo_vetrina,prezzo_vetrina, prezzo_trasporto, venditoreId, venditore_nome, quantita_disponibile, options);
                    this.cartBadge = Number(this.cartBadge.valueOf()+1);
                    return this.cartBadge;
                }else{
                    if(value['quantità']){
                        this.logger.log('putObject: aggiornamento di un item del carrello locale..');
                        // Aggiorno la quantità di un item nel carrello
                        var new_quantità = Number(value['quantità']) + 1;
                        this.logger.log('putObject: aggiornamento della quantità -> '+new_quantità);
                        var costo = Number(new_quantità * Number(prezzo_vetrina)).toFixed(2);
                        this.logger.log('putObject: aggiornamento del costo -> '+costo);
                        this.localCart_updateObject(key,name,image, new_quantità ,Number(prezzo_vetrina).toFixed(2), costo, prezzo_trasporto, venditoreId, venditore_nome, quantita_disponibile, options);
                        return this.cartBadge;
                    }else{
                        this.logger.log('putObject: errore di gestione della quantità di un item del carrello!');
                    }
                }
            });
        }else{
          this.logger.log('putObject fallito.. non rispettate le condizioni dei parametri');
          return this.cartBadge;
        }
      }
    
      // JSON "get" example
      async localCart_getObject(objKey: string) {
        const ret = await Preferences.get({ key: objKey });
        const cartItem = JSON.parse(ret.value);
        return cartItem;
      }
    
      async localCart_updateObject(key: string, name: string, image: string, quantità: number, prezzo_vetrina: string, costo: string, 
        prezzo_trasporto: string, venditoreId: string, venditore_nome: string, quantita_disponibile: number, options: any){
        await Preferences.set({
            key: 'emplace-cart-'+key,
            value: JSON.stringify({
              name: name,
              image: image,
              quantità: ''+quantità,
              costo: Number(costo).toFixed(2),
              id: key,
              prezzo_vetrina: Number(prezzo_vetrina).toFixed(2),
              prezzo_trasporto: prezzo_trasporto,
              venditoreId: venditoreId,
              venditore_nome: venditore_nome,
              quantita_disponibile: quantita_disponibile,
              options: options
            })
          });
      }


      async localCart_storageValues(caller: string) {
        this.logger.log('ShoppingCartManagerService: ['+caller+'] storageValues() launched...');
    
        const { keys } = await Preferences.keys();
        var cart_keys = [];
        for(let key of keys){
          this.logger.log('key read (only) within local storage: '+key);
          if(key.substring(0,13)=='emplace-cart-'){
            cart_keys.push(key);
          }
        }
        return cart_keys;
      }

      localCart_removeItems(caller: string, local_cart: any[]){
        for(let item of local_cart){
          this.logger.log('ShoppingCartManagerService: ['+caller+'] removing local cart item with key = '+item['key']);
          this.localCart_removeItem(item['key']);
        }
      }
    
      async localCart_removeItem(objKey: string) {
        await Preferences.remove({ key: objKey });
      }

      async localCart_putObjectIntoSC(key: string, name: string, image: string, quantità: number, costo: number, prezzo_vetrina: number, 
        prezzo_trasporto: string, venditoreId: string, venditore_nome: string, quantita_disponibile: number, options: any) {
        if(name!=null && image!=null && quantità>0 && costo>0 && prezzo_vetrina>0 && venditoreId!=null && venditore_nome!=null){
          await Preferences.set({
            key: 'emplace-cart-'+key,
            value: JSON.stringify({
              name: name,
              image: image,
              quantità: ''+quantità,
              costo: ''+Number(costo).toFixed(2),
              id: key,
              prezzo_vetrina: ''+Number(prezzo_vetrina).toFixed(2),
              prezzo_trasporto: prezzo_trasporto,
              venditoreId: venditoreId,
              venditore_nome: venditore_nome,
              quantita_disponibile: quantita_disponibile,
              options: options
            })
          });
        }else{
          this.logger.log('putObject fallito.. non rispettate le condizioni dei parametri')
        }
    }

}