import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-mission',
  templateUrl: './mission.component.html',
  styleUrls: ['./mission.component.scss'],
})
export class MissionComponent implements OnInit {

  SLOW_SLIDING_TIME: number = 7000;

  slideIndex: number = 0;
  LAST_SLIDE_INDEX: number = 1;
  FIRST_SLIDE_INDEX: number = 0;

  private slideOpts = {
    initialSlide: 0,
    slidesPerView: 1,
    autoplay: true,
    speed: this.SLOW_SLIDING_TIME,
    loop: true,
    disableOnInteraction: false
  };

  testo1: string = 'Destiniamo la metà dei nostri guadagni perché crediamo che anche il modello di business debba cambiare e diventare più sostenibile. È per questo che per noi è importante che per ogni prodotto acquistato venga compiuta anche un\'azione concreta che permetta di avere un impatto ambientale e sociale positivo e reale.';
  testo2: string = 'Testo 2';
  testo3: string = 'Non esiste un pianeta B ma esistono tanti modi diversi di viverlo, noi ve ne proponiamo alcuni.';
  testo4: string = 'Vogliamo proporre una nuova esperienza di acquisto online, più consapevole, offrendo tanti prodotti sostenibili e permettendo contemporaneamente di destinare parte della spesa per proteggere il nostro pianeta, salvaguardarne la biodiversità e supportare le comunità più deboli.';
  testo5: string = 'Vogliamo aiutare le persone a cambiare il mondo, una piccola azione alla volta';

  constructor(public platform: Platform, private router: Router) { }

  ngOnInit() {}

  toHome(){
    this.router.navigate(['home']);
  }

  slideToRight(){
    this.slideIndex = this.slideIndex + 1;
  }

  slideToLeft(){
    this.slideIndex = this.slideIndex - 1;
  }

}
